import { Localization } from '@lib/i18n/localization'
import { Authorization } from '@modules/authorization/authorization'
import { FeatureToggles } from '@utils/feature_toggle.jsx'
import { PluginManager } from '@modules/plugins/plugin_manager'
import { View } from '@lib/view_context/view-context'

export const SettingsMetadata = (locale, authorization, features, plugins) => {
    const { permissions, proAnalyticsPermissions } = authorization

    return {
        title: {
            label: locale('settings'),
        },
        settings: {
            label: locale('settings'),
            visible: permissions?.settings?.permissions?.read,
        },
        reports: {
            label: locale('settings.reports'),
            visible: proAnalyticsPermissions?.reports?.permissions?.read === true && features.reports.value,
            actions: ['onTrigger'],
        },
        notifications: {
            label: locale('settings.notifications'),
            visible: features.notifications.value && !!permissions?.settings?.notifications?.permissions?.read,
            actions: ['onTrigger'],
        },
        companyInfo: {
            label: locale('settings.companyInformation'),
            visible:
                !!permissions?.settings?.companyInformation?.permissions?.read &&
                plugins?.features?.settings?.companyInformation?.visible?.inject(),
            actions: ['onTrigger'],
        },
        invoices: {
            label: locale('settings.invoices'),
            visible: features.invoices.value && !!permissions?.settings?.invoices?.permissions?.read,
            actions: ['onTrigger'],
        },
        tasks: {},
        ...plugins.features.settings?.metadata?.inject()(locale, permissions, features, plugins),
    }
}

export const SettingsContext = View.observe(Localization, Authorization, FeatureToggles, PluginManager).update(
    (localization, authorization, featureToggles, pluginManager) =>
        SettingsMetadata(localization.text, authorization, featureToggles.features, pluginManager)
)
