import { CATEGORIES, Logger } from '@modules/logging/logger.js'
import { DEFAULT_DICTIONARIES, Dictionaries } from '@modules/dictionaries/dictionaries'
import { Process, stateOf, useEffect, useOnce, useState } from '@prospective/process-router'
import { FeatureToggles } from '@utils/feature_toggle.jsx'
import JobBoosterService from '@services/job_booster_service'

export const ProAnalyticsDictionariesProcess = Process(({ process }) => {
    const logger = Logger('UserDictionariesProcess', CATEGORIES.MAIN)
    const [requestsComplete, setRequestsComplete] = useState(0)
    const [totalRequests, setTotalRequests] = useState(0)
    const once = useOnce()
    const featureToggle = stateOf(FeatureToggles)

    const filterActiveNodes = hierarchy => {
        if (!hierarchy) return undefined
        let filtered = hierarchy
            .filter(node => node.isActive !== false)
            .filter(node => node.hasOwnProperty('children'))
            .map(node => ({ ...node, children: filterActiveNodes(node.children) }))
        return filtered
    }

    const getDictionaries = async () => {
        logger.info('Starting UserDictionaries')
        const canLoadUser =
            featureToggle.features.cockpit.value ||
            featureToggle.features.profiles.value ||
            featureToggle.features.mediaList.value

        if (
            Dictionaries.state !== DEFAULT_DICTIONARIES &&
            Dictionaries.user !== undefined &&
            Dictionaries.organizationStructure !== undefined &&
            Dictionaries.orderStates !== undefined &&
            Dictionaries.mediaLists !== undefined
        )
            process.exit(true)
        else {
            try {
                const logs = []

                const requests = [
                    JobBoosterService()
                        .getHierarchyStructure()
                        .then(result => {
                            setRequestsComplete(requestsComplete + 1)
                            return filterActiveNodes(result)
                        })
                        .catch(error => {
                            logs.push(logger.error.withError(error, 'Loading organization structure failed.'))
                        }),
                    canLoadUser
                        ? JobBoosterService()
                              .getLoggedInUser()
                              .then(result => {
                                  setRequestsComplete(requestsComplete + 1)
                                  return result
                              })
                              .catch(error => {
                                  logs.push(logger.error.withError(error, 'Loading user details failed.'))
                              })
                        : undefined,
                    JobBoosterService()
                        .getOrderStates()
                        .then(result => {
                            setRequestsComplete(requestsComplete + 1)
                            return result
                        })
                        .catch(error => {
                            logs.push(logger.error.withError(error, 'Loading order states failed.'))
                        }),
                    featureToggle.features.mediaList.value
                        ? JobBoosterService()
                              .getMediaFilters()
                              .then(result => {
                                  setRequestsComplete(requestsComplete + 1)
                                  return result
                              })
                        : undefined,
                ]

                setTotalRequests(requests.length)
                const [organizationStructure, user, orderStates, mediaFilters] = await Promise.all(requests)

                Dictionaries.setDictionaries({
                    organizationStructure,
                    user,
                    orderStates,
                    mediaFilters,
                })

                if (logs.length) {
                    const logNumbers = logs.map(logEntry => logEntry.logNumber).join(', ')
                    process.throw(
                        new Error(`Some dictionaries could not be loaded. More details in logs ${logNumbers}`)
                    )
                } else if (requestsComplete === totalRequests) {
                    process.exit(true)
                }
            } catch (error) {
                logger.error.withError(
                    error,
                    'Could not load hierarchy structure. Please refer to earlier logs for details'
                )
                process.throw(error)
            }
        }
    }

    useEffect(() => {
        process.progress = totalRequests > 0 ? requestsComplete / totalRequests : 0
    }, [requestsComplete, totalRequests])

    return async () => {
        await once(getDictionaries)
    }
})

ProAnalyticsDictionariesProcess.label = 'Dictionaries'
