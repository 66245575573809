import { Observer, Stream } from '@lib/stream/stream2.js'
import { RemoteData } from '@lib/remote_data/remote-data.js'
import { tryCatch } from '@prospective/pms-js-utils'
import JobBoosterService from '@services/job_booster_service.js'
import { Logger } from '@modules/logging/logger.js'
import { StreamState } from '@utils/process_hooks.js'

const logger = Logger('User')

// TODO: This doesn't make sense... Clean it up
const [UserDetailsState, updateUserDetailsState] = StreamState()

export const UserDetails = UserDetailsState
export const loadUserDetails = Stream(
    async function* () {
        logger.info('Loading user details')
        yield RemoteData.pending()
        const [error, user] = await tryCatch(JobBoosterService().getLoggedInUser)()
        if (error) {
            const { logNumber } = logger.error.withError(error, 'Loading user details failed with an error')
            throw RemoteData
                .error(locale => locale('loadingUserDetailsFailed', { logNumber }))
                .logNumber(logNumber)
        }
        logger.info('User details loaded')
        return RemoteData.success().setValue(user)
    })
    .compose(Observer(updateUserDetailsState))

