import { Publisher, tryCatch, tryCatchSync } from '@prospective/pms-js-utils'
import { Permissions } from '@modules/authorization/permissions.js'
import { Stream } from '@lib/stream/stream2.js'
import { Logger } from '@modules/logging/logger.js'
import JobBoosterService from '@services/job_booster_service.js'
import { RemoteData } from '@lib/remote_data/remote-data.js'
import { UserDetails } from '@modules/user/user.js'

let originalPermissions
let isUserMigrated = UserDetails.state?.value?.jb3_migrated === true // Dictionaries.user?.jb3_migrated
UserDetails.subscribe(userDetailsState => {
    const userDetails = userDetailsState.value
    if (userDetails?.jb3_migrated === isUserMigrated) return
    isUserMigrated = userDetails?.jb3_migrated
    setState({
        ...Authorization.state,
        permissions: useMigratedFlag(originalPermissions),
    })
})

const useMigratedFlag = permissions => {
    originalPermissions = permissions
    const userDetails = UserDetails.state?.value
    const isUserMigrated = userDetails?.jb3_migrated
    const isAdminUser = userDetails?.isAdminUser
    if (isUserMigrated || isAdminUser) return permissions
    return Permissions(permissions).map((permissions, path) => {
        if (path.startsWith('settings.profiles') || path.startsWith('proAnalytics') || path === 'application')
            return permissions
        return {
            read: false,
            write: false,
            delete: false,
            execute: false,
        }
    })
}

const logger = Logger('Authorization')

/**
 * Anonymous user's roles
 */
const DEFAULT_ROLES = []

/**
 * Anonymous user's permissions
 */
export const DEFAULT_PERMISSIONS = useMigratedFlag({
    application: {
        permissions: {},
    },
    settings: {
        permissions: {
            read: true,
            write: true,
        },
        personalSettings: {
            locale: {
                permissions: {
                    read: true,
                    write: true,
                },
            },
        },
        reports: {
            widget: {
                permissions: {
                    read: false,
                },
            },
            management: {
                permissions: {
                    read: false,
                    write: false,
                    execute: false,
                },
            },
        },
        mediaLists: {
            permissions: {
                read: false,
                write: false,
                execute: true,
            },
        },
    },
})

export const DEFAULT_PROANALYTICS_PERMISSIONS = {
    permissions: {
        read: false,
        write: false,
        execute: true,
    },
    performance: {
        permissions: {
            read: false,
            write: false,
            execute: false,
        },
    },
    kpi: {
        permissions: {
            read: false,
            write: false,
            execute: false,
        },
    },
    costsPerMedium: {
        permissions: {
            read: false,
            write: false,
            execute: false,
        },
    },
    costs: {
        permissions: {
            read: false,
            write: false,
            execute: false,
        },
    },
    candidateJourney: {
        permissions: {
            read: false,
            write: false,
            execute: false,
        },
    },
    numberOfPositions: {
        permissions: {
            read: false,
            write: false,
            execute: false,
        },
    },
    positionMarket: {
        permissions: {
            read: false,
            write: false,
            execute: false,
        },
    },
    reports: {
        widget: {
            permissions: {
                read: false,
            },
        },
        management: {
            permissions: {
                read: false,
                write: false,
                execute: false,
            },
        },
    },
}

const structureProAnalyticsPermissions = (serviceResponse = { grantedWidgets: [] }) => ({
    permissions: {
        read: !!serviceResponse.grantedWidgets.length,
    },
    performance: {
        permissions: {
            read: serviceResponse.grantedWidgets.includes('performanceWidget'),
        },
    },
    kpi: {
        permissions: {
            read: serviceResponse.grantedWidgets.includes('kpiWidget'),
        },
    },
    costsPerMedium: {
        permissions: {
            read: serviceResponse.grantedWidgets.includes('costsPerMediumWidget'),
        },
    },
    costs: {
        permissions: {
            read: serviceResponse.grantedWidgets.includes('costsWidget'),
        },
    },
    candidateJourney: {
        permissions: {
            read: serviceResponse.grantedWidgets.includes('candidateJourneyWidget'),
        },
    },
    numberOfPositions: {
        permissions: {
            read: serviceResponse.grantedWidgets.includes('jobsCountWidget'),
        },
    },
    positionMarket: {
        permissions: {
            read: serviceResponse.grantedWidgets.includes('careerCenterWidget'),
        },
    },
    reports: {
        widget: {
            permissions: {
                read: serviceResponse.grantedWidgets.includes('reports'),
            },
        },
        management: {
            permissions: {
                read: serviceResponse.grantedAuthorities.includes('READ_REPORT'),
                write: serviceResponse.grantedAuthorities.includes('WRITE_REPORT'),
                execute: serviceResponse.grantedAuthorities.includes('PERFORM_REPORT_QUERY'),
            },
        },
    },
})

const structurePermissions = globalPermissions => {
    // Whether the user can read/write media lists at all.
    const readWriteMediaLists =
        globalPermissions?.STAT_PRAEFERENZLISTE_STD_VIEW ||
        globalPermissions?.STAT_PRAEFERENZLISTE_STD ||
        globalPermissions?.PRAEFERENZLISTE_PERSOENLICHE ||
        globalPermissions?.STAT_PRAEFERENZLISTE_VIEW ||
        globalPermissions?.STAT_PRAEFERENZLISTE

    return {
        settings: {
            permissions: {
                read: true,
            },
            invoices: {
                permissions: {
                    read: true,
                    write: true,
                },
            },
            profiles: {
                permissions: {
                    read: globalPermissions?.MITARBEITER_TYP_READ,
                    write: globalPermissions?.MITARBEITER_TYP_WRITE,
                    delete: globalPermissions?.MITARBEITER_TYP_WRITE,
                },
                systemUser: {
                    permissions: {
                        read: true,
                        write: globalPermissions?.MITARBEITER_TYP_SYSUSER_WRITE,
                        delete: globalPermissions?.MITARBEITER_TYP_SYSUSER_WRITE,
                    },
                },
            },
            notifications: {
                permissions: {
                    read: true,
                    write: true,
                },
            },
            applicationChannel: {
                permissions: {
                    read: true,
                    write: true,
                },
            },
            companyInformation: {
                permissions: {
                    read: true,
                    write: true,
                },
            },
            jobBoard: {
                permissions: {
                    read: true,
                    write: true,
                },
            },
            inviteUser: {
                permissions: {
                    read: true,
                    write: true,
                },
            },
            imageLibrary: {
                permissions: {
                    read: globalPermissions?.MITARBEITER_TYP_READ,
                    write: globalPermissions?.MITARBEITER_TYP_WRITE,
                    delete: globalPermissions?.MITARBEITER_TYP_WRITE,
                },
            },
            personalSettings: {
                permissions: {
                    read: true,
                    write: true,
                },
            },
        },
        mediaLists: {
            permissions: {
                read: readWriteMediaLists ?? false,
                write: readWriteMediaLists ?? false,
            },
            companyDefaultList: {
                permissions: {
                    read: globalPermissions?.STAT_PRAEFERENZLISTE_STD_VIEW ?? false,
                    write: globalPermissions?.STAT_PRAEFERENZLISTE_STD ?? false,
                },
            },
            personalList: {
                permissions: {
                    read:
                        (globalPermissions?.PRAEFERENZLISTE_PERSOENLICHE ||
                            globalPermissions?.STAT_PRAEFERENZLISTE_VIEW) ??
                        false,
                    write: globalPermissions?.STAT_PRAEFERENZLISTE ?? false,
                },
            },
            mediaFlags: {
                permissions: {
                    read: globalPermissions?.STAT_PRAEFERENZLISTE_STD_VIEW ?? false,
                    write: globalPermissions?.PRAEFERENZLISTE_AUTOBOOKING ?? false,
                },
            },
        },
        orders: {
            permissions: {
                read: true,
                write: true,
                delete: true,
                execute: true,
            },
        },
        cockpit: {
            permissions: {
                read: true,
                write: true,
            },
        },
    }
}

const [change, publishChange] = Publisher()

let state = {
    userId: undefined,
    currentCompanyId: undefined,
    roles: DEFAULT_ROLES,
    permissions: DEFAULT_PERMISSIONS,
    proAnalyticsPermissions: DEFAULT_PROANALYTICS_PERMISSIONS,
    get isAdminUser() {
        return state.roles.includes(ROLE_PMS_ROOT)
    },
}

function setPermissions(permissions) {
    setState({ ...state, permissions })
}

function setProAnalyticsPermissions(proAnalyticsPermissions) {
    setState({ ...state, proAnalyticsPermissions })
}

function setRoles(roles) {
    state.roles = roles
    publishChange(state)
}

function reset() {
    setState({
        roles: DEFAULT_ROLES,
        permissions: DEFAULT_PERMISSIONS,
    })
}

function setState(partialState) {
    state.roles = partialState.roles
    state.permissions = partialState.permissions
    state.proAnalyticsPermissions = partialState.proAnalyticsPermissions
    state.currentCompanyId = partialState.currentCompanyId
    publishChange(state)
}

const loadPermissions = Stream(async function* (companyNodeId) {
    if (!companyNodeId) return RemoteData.success().setValue(DEFAULT_PERMISSIONS)
    logger.info('Loading permissions')
    yield RemoteData.pending()
    const [error, result] = await tryCatch(JobBoosterService().getPermissions)(companyNodeId)
    if (error) {
        const { logNumber } = logger.error.withError(error, 'Failed to load the permissions')
        throw RemoteData.error(locale => locale('loadingPermissionsFailed', { logNumber }))
            .cause(error)
            .logNumber(logNumber)
    }

    const [permissionsStructureError, permissionsStructure] = tryCatchSync(structurePermissions)(result)

    if (permissionsStructureError) {
        const { logNumber } = logger.error.withError(permissionsStructureError, 'Could not map received permissions')
        throw RemoteData.error(locale => locale('loadingPermissionsFailed', { logNumber }))
            .cause(permissionsStructureError)
            .logNumber(logNumber)
    }
    logger.info('Permissions loaded')
    return RemoteData.success().setValue(useMigratedFlag(permissionsStructure))
})

const loadProAnalyticsPermissions = Stream(async function* () {
    logger.info('Loading ProAnalytics permissions')
    yield RemoteData.pending()
    const [error, result] = await tryCatch(JobBoosterService().getProAnalyticsPermissions)()
    if (error) {
        if (error?.statusCode === 401) RemoteData.success().setValue(DEFAULT_PROANALYTICS_PERMISSIONS)

        const { logNumber } = logger.error.withError(error, 'Failed to load the permissions')
        throw RemoteData.error(locale => locale('loadingPermissionsFailed', { logNumber }))
            .cause(error)
            .logNumber(logNumber)
    }

    const [permissionsStructureError, permissionsStructure] = tryCatchSync(structureProAnalyticsPermissions)(result)

    if (permissionsStructureError) {
        const { logNumber } = logger.error.withError(
            permissionsStructureError,
            'Could not map received ProAnalytics permissions'
        )
        throw RemoteData.error(locale => locale('proAnalytics.loadingPermissionsError', { logNumber }))
            .cause(permissionsStructureError)
            .logNumber(logNumber)
    }
    logger.info('ProAnalytics permissions loaded')
    return RemoteData.success().setValue(permissionsStructure)
})

export const Authorization = {
    get state() {
        return {
            ...state,
            get isAdminUser() {
                return state.roles.includes(ROLE_PMS_ROOT)
            },
        }
    },
    set state(value) {
        setState(value)
    },
    get permissions() {
        return state.permissions
    },
    set permissions(value) {
        setPermissions(value)
    },
    get proAnalyticsPermissions() {
        return state.proAnalyticsPermissions
    },
    set proAnalyticsPermissions(value) {
        setProAnalyticsPermissions(value)
    },
    get currentCompanyId() {
        return state.currentCompanyId
    },
    set currentCompanyId(currentCompanyId) {
        setState({ ...state, currentCompanyId })
    },
    get roles() {
        return state.roles
    },
    set roles(value) {
        setRoles(value)
    },
    get isAdminUser() {
        return state.roles.includes(ROLE_PMS_ROOT)
    },
    get reset() {
        return reset
    },
    ...change,
    loadPermissions,
    loadProAnalyticsPermissions,
}

export const ROLE_PMS_ROOT = 'ROLE_PMS_ROOT'
Authorization.ROLE_PMS_ROOT = ROLE_PMS_ROOT
