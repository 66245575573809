import { useRef } from 'react'
import { useViewModel } from '@lib/view_context/view-model'
import { Avatar, Dropdown } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { SettingsContext } from '@views/settings/settings.context.js'
import './profile_panel.scss'
import { ApplicationContext } from '@views/application/application.context.js'

export default function ProfilePanel({ className = '', ...props }) {
    const popupContainer = useRef(null)
    const viewModel = useViewModel(ApplicationContext)
    const settingsViewModel = useViewModel(SettingsContext)
    const authenticatedValue = viewModel.authenticated.value

    const settingsVisible =
        settingsViewModel.settings.visible && (
            settingsViewModel.reports.visible ||
            settingsViewModel.notifications.visible ||
            settingsViewModel.profiles?.visible ||
            settingsViewModel.invoices?.visible ||
            settingsViewModel.companyInfo?.visible
        )

    let items = []
    if (authenticatedValue) {
        items.push(
            { key: 'username', label: <span data-test-key="username">{viewModel.fullName.value}</span> },
            {
                key: 'logout',
                label: <span data-test-key="logout">{viewModel.logOut.label}</span>,
                onClick: () => viewModel.logOut.onTrigger(),
            }
        )
        if (settingsVisible) {
            items.splice(
                1,
                0,
                { type: 'divider' },
                {
                    label: <span data-test-key="settings">{viewModel?.settings?.label}</span>,
                    key: 'settings',
                    onClick: () => viewModel.settings.onTrigger(),
                }
            )
        }

        items.splice(
            1,
            0,
            { type: 'divider' },
            {
                label: <span data-test-key="password">{viewModel?.password?.label}</span>,
                key: 'password',
                onClick: () => viewModel.password.onTrigger(),
            }
        )

    } else
        items.push({
            key: 'login',
            label: <span data-test-key="login">{viewModel.logIn.label}</span>,
            onClick: () => viewModel.logIn.onTrigger(),
        })

    if (viewModel.fullName.value === undefined) items = items.filter(menuItem => menuItem.key !== 'username')

    return (
        <div ref={popupContainer} className={`profile-panel ${className}`} {...props}>
            <Dropdown
                role="tooltip"
                menu={{ items }}
                placement="bottom"
                getPopupContainer={() => popupContainer.current}
                trigger={['click']}
                overlayClassName="profile-panel__dropdown"
            >
                {viewModel.profileImage?.value ? (
                    <img src={viewModel.profileImage.value} className="profile-panel__user-profile-image" />
                ) : (
                    <Avatar size={[32, 32]} icon={<UserOutlined />} data-testid="profile-panel" />
                )}
            </Dropdown>
        </div>
    )
}
