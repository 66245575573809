import { O } from '@prospective/pms-js-utils'

const Image = lang => ({
    language: lang,
    hash: '',
    type: '',
    image_pool_id: undefined,
    url: undefined,
    filename: undefined,
    width: undefined,
    height: undefined,
    by_width: false,
    focal_point: {
        x: undefined,
        y: undefined,
    },
})

const createImageEntry = () => ({
    id: undefined,
    image_pool_entry_id: undefined,
    language_specific_images: false,
    label: {
        de: '',
        en: '',
        it: '',
        fr: '',
    },
    images: {
        de: Image('de'),
        en: Image('en'),
        it: Image('it'),
        fr: Image('fr'),
    },
})

const fromDTO = dto => ({
    id: dto.imageId,
    groupId: dto.groupId,
    label: dto.label,
    image_pool_entry_id: dto.image_pool_entry_id,
    images: dto.images,
    language_specific_images: dto.language_specific_images,
    image_pool_data_type: dto.image_pool_data_type,
})

const toDTO = imageEntry => {
    const prepareImageEntries = { ...imageEntry.images }
    Object.keys(prepareImageEntries).forEach(key => {
        if (!prepareImageEntries?.[key]?.hash) prepareImageEntries[key] = null
    })
    return {
        id: imageEntry.id,
        label: imageEntry.label,
        image_pool_entry_id: imageEntry.image_pool_entry_id,
        images: prepareImageEntries,
        language_specific_images: imageEntry.language_specific_images,
        image_pool_data_type: imageEntry.image_pool_data_type,
    }
}

const isObject = object => object != null && typeof object === 'object'

const deepEqual = (object1, object2) => {
    const keys1 = Object.keys(object1)
    const keys2 = Object.keys(object2)

    if (keys1.length !== keys2.length) return false

    return keys1.every(key => {
        const val1 = object1[key]
        const val2 = object2[key]
        const areObjects = isObject(val1) && isObject(val2)
        if (areObjects) return deepEqual(val1, val2)
        else return val1 === val2
    })
}

const compare = (imageEntry1 = {}, imageEntry2 = {}) => {
    const result = {
        id: imageEntry1.id === imageEntry2.id,
        image_pool_entry_id: imageEntry1.image_pool_entry_id === imageEntry2.image_pool_entry_id,
        language_specific_images: imageEntry1.language_specific_images === imageEntry2.language_specific_images,
        images: deepEqual(imageEntry1.images, imageEntry2.images),
        label: deepEqual(imageEntry1.label, imageEntry2.label),
    }

    const isEqual = () => O(result).every(value => value === true)
    return { ...result, isEqual }
}

const createInterfaceProperties = (host, properties) => {
    Object.entries(properties).forEach(([name, value]) => {
        Reflect.defineProperty(host, name, { get: () => value, enumerable: false })
    })
    return host
}

const ImageEntryType = Symbol('ImageEntryType')
export const ImageEntry = (imageEntry = {}) => {
    if (imageEntry[ImageEntryType] === ImageEntryType) return imageEntry
    const libraryObject = { ...createImageEntry(), ...imageEntry }

    createInterfaceProperties(libraryObject, {
        [ImageEntryType]: ImageEntry,
        toDTO: () => toDTO(libraryObject),
        fromDTO: dto => fromDTO(dto),
        update: nextObj => {
            return ImageEntry({
                ...libraryObject,
                ...nextObj,
            })
        },
    })
    return libraryObject
}

ImageEntry.compare = compare
ImageEntry.CREATE = 'create'
ImageEntry.UPDATE = 'update'
