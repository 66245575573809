import { isSameDay } from 'date-fns'
import { distinct, haveSameElements, tryCatch } from '@prospective/pms-js-utils'
import { Localization } from '@lib/i18n/localization.js'
import { getSearchFields } from '@views/pro_analytics/pro_analytics_utils.js'
import {
    abortAndCreateNewController,
    createErrorStatus,
    createPendingStatus,
    createSuccessStatus,
    STATUS_PENDING,
} from '@utils/request_statuses'
import { CATEGORIES, Logger } from '@lib/../../app/modules/logging/logger'
import { SearchSuggestions } from '@views/pro_analytics/search_suggestions'
import { ProAnalyticsJobProcess } from '@views/pro_analytics/pro-analytics-job/pro-analytics-job.process.js'
import { ProAnalyticsOrganizationFilter } from '@views/pro_analytics/pro_analytics_organization_filter.js'
import JobBoosterService, { JobBoosterServiceError } from '@services/job_booster_service'
import { stateOf, useCallback, useEffect, useMemo, useMemoLast, useState } from '@prospective/process-router'

const logger = Logger('ProAnalytics:search', CATEGORIES.MAIN)
const service = JobBoosterService()
const searchLimit = 1000

const areSearchParamsEqual = (params1, params2) =>
    isSameDay(params1.from, params2.from) &&
    isSameDay(params1.to, params2.to) &&
    params1.searchTerm === params2.searchTerm &&
    haveSameElements(params1.organizationStructureFilter, params2.organizationStructureFilter)

const createSuggestionKey = (key, value) => `${key}-${value}`

export const ProAnalyticsSearch = ({
    params,
    setParams,
    view,
    suggestionsParser
}) => {
    const [searchTerm, setSearchTerm] = useState()
    const [selectedSuggestion, setSelectedSuggestion] = useState()
    const [searchSuggestions, setSearchSuggestions] = useState()
    const [activeSearchSuggestions, setActiveSearchSuggestions] =  useState()
    const [searchSuggestionsStatus, setSearchSuggestionsStatus] = useState()
    const [searchAbortController, setSearchAbortController] = useState()
    const [selectedOrganisationNodes, setSelectedOrganisationNodes] = useState()
    const [isOrganisationStructureFilterValid, setIsOrganisationStructureFilterValid] = useState(false)
    const { locale } = stateOf(Localization)
    const SEARCH_FIELDS = getSearchFields(locale)

    const organizationStructureFilter = ProAnalyticsOrganizationFilter({
        params,
        setParams
    })

    const updateSearchTerm = useCallback(value => {
        setSearchTerm(value || undefined)
        if (!value) setSearchSuggestions(activeSearchSuggestions)
    })

    const clearSearch = useCallback(() => {
        setSelectedSuggestion(undefined)
        setSearchSuggestions(undefined)
        setActiveSearchSuggestions(undefined)
        setSearchTerm(undefined)
        setParams({
            textFilter: undefined,
            internalTitle: undefined,
            externalTitle: undefined,
            postingId: undefined,
            publicationId: undefined,
            orderId: undefined,
        })
    })

    const reset = useCallback(() => {
        /*setParams({
			textFilter: undefined,
			internalTitle: undefined,
			externalTitle: undefined,
			postingId: undefined,
			publicationId: undefined,
			orderId: undefined,
		})*/
        setSelectedSuggestion(undefined)
        setSearchSuggestions(undefined)
        setActiveSearchSuggestions(undefined)
        setSearchTerm(undefined)
    })

    const cancelSearch = useCallback(() => {
        setSearchTerm(params.textFilter)
        setSearchSuggestions(activeSearchSuggestions)
    })

    const useSuggestion = useCallback(suggestionKey => {
        if (suggestionKey === undefined) {
            setParams({
                textFilter: undefined,
                recruiterKeyword: undefined,
                internalTitle: undefined,
                externalTitle: undefined,
                postingId: undefined,
                publicationId: undefined,
                orderId: undefined,
            })
            setSearchTerm(undefined)
            setSelectedSuggestion(undefined)
            setSearchSuggestions(undefined)
            setActiveSearchSuggestions(undefined)
            return
        }
        const suggestion = searchSuggestions?.suggestions
            .map(group => [group, ...(group.suggestions || [])])
            .flat()
            .filter(item => item)
            .find(item => item && item.value === suggestionKey)

        if (suggestion?.value.startsWith('all.')) {
            setParams({
                textFilter: suggestion.searchTerm,
                internalTitle: undefined,
                externalTitle: undefined,
                recruiterKeyword: undefined,
                postingId: undefined,
                publicationId: undefined,
                orderId: undefined,
            })
            setSearchTerm(suggestion.searchTerm)
            setSelectedSuggestion(suggestion)
            setActiveSearchSuggestions(searchSuggestions)
        } else if (
            suggestion?.match === SEARCH_FIELDS.recruiterId.key ||
            suggestion?.match === SEARCH_FIELDS.recruiterName.key
        ) {
            setParams({
                recruiterKeyword: undefined,
                recruiterFilter: distinct([
                    ...params.recruiterFilter.filter(id => id !== `"${params.recruiterKeyword}"`),
                    parseInt(suggestion[SEARCH_FIELDS.recruiterId.key]),
                ]),
            })
            setSearchTerm(undefined)
            setSelectedSuggestion(undefined)
            setActiveSearchSuggestions(undefined)
            setSelectedSuggestion(undefined)
        } else if (suggestion?.match === SEARCH_FIELDS.orderId.key) {
            ProAnalyticsJobProcess({ jobId: suggestion.stelle_id })
        } else if (suggestion?.match === SEARCH_FIELDS.postingId.key) {
            ProAnalyticsJobProcess({ jobId: suggestion[suggestion.match] })
        } else if (suggestion?.match === SEARCH_FIELDS.publicationId.key) {
            ProAnalyticsJobProcess({ jobId: suggestion.stelle_id })
        } else if (suggestion?.match === SEARCH_FIELDS.internalTitle.key) {
            setParams({
                textFilter: undefined,
                internalTitle: suggestion[suggestion.match],
                externalTitle: undefined,
                postingId: undefined,
                publicationId: undefined,
                orderId: undefined,
            })
            setSearchTerm(undefined)
            setSelectedSuggestion(suggestion)
            setActiveSearchSuggestions(searchSuggestions)
        } else if (suggestion?.match === SEARCH_FIELDS.externalTitle.key) {
            setParams({
                textFilter: undefined,
                internalTitle: undefined,
                externalTitle: suggestion[suggestion.match],
                postingId: undefined,
                publicationId: undefined,
                orderId: undefined,
            })
            setSearchTerm(undefined)
            setSelectedSuggestion(suggestion)
            setActiveSearchSuggestions(searchSuggestions)
        } else if (suggestion?.match === SEARCH_FIELDS.atsId.key) {
            ProAnalyticsJobProcess({ jobId: suggestion.stelle_id })
        }
    })

    const search = useMemo(
        async ({ searchTerm, from, to, selectedOrganisationNodes }, limit, isOrganisationFilterValid) => {
            if (!searchTerm || !isOrganisationFilterValid) return { results: [], hit_count: 0 }
            const requestController = abortAndCreateNewController(searchAbortController)
            setSearchAbortController(requestController)
            setSearchSuggestionsStatus(createPendingStatus())
            const searchParams = {
                searchTerm,
                from,
                to,
                size: limit,
                filters: {
                    [SEARCH_FIELDS.hierarchyNodeIds.key]: selectedOrganisationNodes || [],
                },
                fields: ['ats_id'],
            }
            const [error, result] = await tryCatch(service.proAnalyticsSearch)(
                searchParams,
                requestController.signal
            )
            if (error && error.type !== JobBoosterServiceError.ABORT_ERROR) {
                const logEntry = logger.info.withError(error, 'Could not perform a search')
                setSearchSuggestionsStatus(
                    createErrorStatus(
                        locale('proAnalytics.search.error', {
                            logNumber: logEntry.logNumber,
                        })
                    )
                )
                return {}
            }
            setSearchSuggestionsStatus(createSuccessStatus())
            result.searchTerm = searchTerm
            return result
        },
        [areSearchParamsEqual, () => true],
        10
    )

    const createArtificialSuggestions = (field, value, label, locale) => {
        const searchFields = getSearchFields(locale)
        const artificialSuggestion = {
            match: searchFields[field].key,
            label,
            hitCount: undefined,
            [searchFields[field].key]: value,
            value: createSuggestionKey(searchFields[field].key, value),
        }
        return {
            searchTerm: undefined,
            hitCount: undefined,
            suggestions: [artificialSuggestion],
        }
    }

    const createArtificialOrderIdSuggestions = useMemoLast((orderId, locale) =>
        createArtificialSuggestions(
            'orderId',
            orderId,
            locale('proAnalytics.search.orderId', { id: orderId }),
            locale
        )
    )

    const createArtificialPostingIdSuggestions = useMemoLast((postingId, locale) =>
        createArtificialSuggestions(
            'postingId',
            postingId,
            locale('proAnalytics.search.postingId', { id: postingId }),
            locale
        )
    )

    const createArtificialPublicationIdSuggestions = useMemoLast((publicationId, locale) =>
        createArtificialSuggestions(
            'publicationId',
            publicationId,
            locale('proAnalytics.search.publicationId', { id: publicationId }),
            locale
        )
    )

    const createArtificialInternalTitleSuggestions = useMemoLast((title, locale) =>
        createArtificialSuggestions(
            'internalTitle',
            title,
            locale('proAnalytics.search.jobTitle', { jobTitle: title }),
            locale
        )
    )

    const createArtificialExternalTitleSuggestions = useMemoLast((title, locale) =>
        createArtificialSuggestions(
            'externalTitle',
            title,
            locale('proAnalytics.search.externalJobTitle', { jobTitle: title }),
            locale
        )
    )

    const getSearchSuggestions = useMemoLast(suggestionsParser)

    const shouldUseOrderIdSuggestions = (orderId, searchTerm, searchSuggestions) => {
        const suggestionKey = createSuggestionKey(SEARCH_FIELDS.orderId.key, orderId)
        return (
            orderId &&
            !searchTerm &&
            !SearchSuggestions(searchSuggestions?.suggestions).findByValue(suggestionKey)
        )
    }

    const shouldUsePostingIdSuggestions = (postingId, searchTerm, searchSuggestions) => {
        const suggestionKey = createSuggestionKey(SEARCH_FIELDS.postingId.key, postingId)
        return (
            postingId &&
            !searchTerm &&
            !SearchSuggestions(searchSuggestions?.suggestions).findByValue(suggestionKey)
        )
    }

    const shouldUsePublicationIdSuggestions = (publicationId, searchTerm, searchSuggestions) => {
        const suggestionKey = createSuggestionKey(SEARCH_FIELDS.publicationId.key, publicationId)
        return (
            publicationId &&
            !searchTerm &&
            !SearchSuggestions(searchSuggestions?.suggestions).findByValue(suggestionKey)
        )
    }

    const shouldUseInternalTitleSuggestions = (internalTitle, searchTerm, searchSuggestions) => {
        const suggestionKey = createSuggestionKey(SEARCH_FIELDS.internalTitle.key, internalTitle)
        return (
            internalTitle &&
            !searchTerm &&
            !SearchSuggestions(searchSuggestions?.suggestions).findByValue(suggestionKey)
        )
    }

    const shouldUseExternalTitleSuggestions = (externalTitle, searchTerm, searchSuggestions) => {
        const suggestionKey = createSuggestionKey(SEARCH_FIELDS.externalTitle.key, externalTitle)
        return (
            externalTitle &&
            !searchTerm &&
            !SearchSuggestions(searchSuggestions?.suggestions).findByValue(suggestionKey)
        )
    }

    const getSuggestionByValue = value =>
        searchSuggestions?.suggestions?.find(suggestion => suggestion.value === value)

    const viewUpdate = ( fields ) => {
        organizationStructureFilter.viewUpdate(fields)
        fields.searchSuggestions.dictionary = searchSuggestions
        fields.searchSuggestions.isLoading = searchSuggestionsStatus?.status === STATUS_PENDING
        fields.searchSuggestions.value = getSuggestionByValue(
            selectedSuggestion?.value || `all.${searchTerm}`
        )?.value

        fields.search.disabled = !isOrganisationStructureFilterValid
        fields.search.onChange = updateSearchTerm
        fields.clearSearch.onTrigger = clearSearch
        fields.cancelSearch.onTrigger = cancelSearch
        fields.searchSuggestions.onChange = useSuggestion
        fields.resetFilters.onTrigger = reset
    }

    useEffect(() => {
        setSearchTerm(params.textFilter)
        setSelectedSuggestion(getSuggestionByValue(`all.${params.textFilter}`))
    }, [params.textFilter])

    const update = async () => {
        const organizationFilterState = await organizationStructureFilter.update()
        setSelectedOrganisationNodes(organizationFilterState.selectedOrganizationNodes)
        setIsOrganisationStructureFilterValid(organizationFilterState.isOrganisationStructureFilterValid)

        const searchTermFilter = searchTerm || params.textFilter
        const filters = {
            searchTerm: searchTermFilter,
            from: params.dateRangeFilter?.from,
            to: params.dateRangeFilter?.to,
            selectedOrganisationNodes,
        }
        const searchResult = await search(filters, searchLimit, isOrganisationStructureFilterValid)

        let newSearchSuggestions = getSearchSuggestions(searchResult, searchTermFilter, locale, searchLimit)

        /**
         * When the process is initialized with parameters where orderId, postingId, publicationId, title or externalTitle
         * is set, searchSuggestions are empty.
         * In order to properly set the value of the search field (which in this case acts as a orderId filter) we need
         * to create an artificial searchSuggestions with just one element representing given order ID.
         * For the sake of simplicity we use the same logic when the user first performs a search and selects an order ID,
         * but theoretically we should be able to use the suggestion from search result.
         */
        const useOrderIdSuggestions = shouldUseOrderIdSuggestions(
            params.orderId,
            searchTerm,
            newSearchSuggestions
        )
        const usePostingIdSuggestions = shouldUsePostingIdSuggestions(
            params.postingId,
            searchTerm,
            newSearchSuggestions
        )
        const usePublicationIdSuggestions = shouldUsePublicationIdSuggestions(
            params.publicationId,
            searchTerm,
            newSearchSuggestions
        )
        const useInternalTitleSuggestions = shouldUseInternalTitleSuggestions(
            params.internalTitle,
            searchTerm,
            newSearchSuggestions
        )
        const useExternalTitleSuggestions = shouldUseExternalTitleSuggestions(
            params.externalTitle,
            searchTerm,
            newSearchSuggestions
        )
        newSearchSuggestions = useOrderIdSuggestions
            ? createArtificialOrderIdSuggestions(params.orderId, locale)
            : usePostingIdSuggestions
            ? createArtificialPostingIdSuggestions(params.postingId, locale)
            : usePublicationIdSuggestions
            ? createArtificialPublicationIdSuggestions(params.publicationId, locale)
            : useInternalTitleSuggestions
            ? createArtificialInternalTitleSuggestions(params.internalTitle, locale)
            : useExternalTitleSuggestions
            ? createArtificialExternalTitleSuggestions(params.externalTitle, locale)
            : newSearchSuggestions
        const newSelectedSuggestion =
            useOrderIdSuggestions ||
            usePostingIdSuggestions ||
            usePublicationIdSuggestions ||
            useInternalTitleSuggestions ||
            useExternalTitleSuggestions
                ? newSearchSuggestions.suggestions[0]
                : SearchSuggestions(newSearchSuggestions?.suggestions).findByValue(
                      selectedSuggestion?.value
                  ) ||
                  SearchSuggestions(newSearchSuggestions.suggestions).findByValue(`all.${searchTermFilter}`)

        setSearchSuggestions(newSearchSuggestions)
        setSelectedSuggestion(newSelectedSuggestion)
    }

    return { searchTerm, searchSuggestions, activeSearchSuggestions, selectedSuggestion, update, viewUpdate }
}
