import {
    deduplicate,
    distinct,
    haveSameElements,
    isDateValid,
    memoizeLast,
    memoizeLastScoped,
    O,
} from '@prospective/pms-js-utils'
import { addDays, differenceInCalendarDays, endOfDay, startOfDay, subDays } from 'date-fns'
import { Localization } from '@lib/i18n/localization'

export const getSearchFields = locale => ({
    orderId: { key: 'auftrags_id', label: locale('orderId') },
    hierarchyNodeIds: { key: 'hk_id', label: locale('organizationStructure') },
    hierarchyNodeId: { key: 'h_id', label: locale('organizationStructure') },
    hierarchyNodeName: { key: 'h_name', label: locale('companyName') },
    publicationId: { key: 'publication_id', label: locale('publicationId') },
    recruiterId: { key: 'recruiter_id', label: locale('recruiterId') },
    recruiterName: { key: 'recruiter_name', label: locale('recruiter') },
    recruiterKeyword: { key: 'recruiter_name_keyword', label: locale('recruiter') },
    mediumType: { key: 'medien_typ', label: locale('mediaType') },
    mediumId: { key: 'medium_id', label: locale('mediumId') },
    mediumName: { key: 'medium_name', label: locale('medium') },
    postingId: { key: 'stelle_id', label: locale('postingId') },
    title: { key: 'title', label: locale('jobAd.title') },
    externalTitle: { key: 'externer_stellentitel', label: locale('jobAd.externalTitle') },
    internalTitle: { key: 'interner_stellentitel', label: locale('jobAd.title') },
    fieldOfActivity: {
        key: 'attributes.field_of_activity.id.keyword',
        label: locale('fieldOfActivity'),
    },
    industry: { key: 'attributes.BRANCHE.id.keyword', label: locale('industry') },
    atsId: { key: 'ats_id', label: 'ATS ID', backendKey: 'stelle_key_extern.keyword' },
})

export const getDictionaryFields = locale => ({
    // TODO Fix typo in 'field_of_activty' once correct in the backend
    // TODO Localize
    recruiters: { key: 'recruiters', label: locale('recruiters') },
    medium: { key: 'medien', label: locale('media') },
    fieldOfActivity: { key: 'field_of_activty', label: locale('fieldOfActivity') },
    industry: { key: 'industry', label: locale('industry') },
    atsId: { key: 'ats_id', label: 'ATS ID' },
})

export const JOB_COUNT_FIELDS = {
    // TODO dynamic localization:
    jobCount: { key: 'amount', label: 'Job count' },
    publications: { key: 'amountPublications', label: Localization.locale('publications') },
    newOnes: { key: 'online', label: Localization.locale('proAnalytics.jobCountStatistics.new') },
    expired: { key: 'offline', label: Localization.locale('proAnalytics.jobCountStatistics.inactive') },
}

export const isSameDate = (date1, date2) =>
    !date1 || !date2
        ? !date1 && !date2
        : date1.getFullYear() === date2.getFullYear() &&
          date1.getMonth() === date2.getMonth() &&
          date1.getDate() === date2.getDate()

const day = 1000 * 60 * 60 * 24
export function DateRange(dateRange = { from: undefined, to: undefined }) {
    return {
        toString: () => `${dateRange.from?.toString()} - ${dateRange.to?.toString()}`,
        from: dateRange.from,
        to: dateRange.to,
        equal: value => isSameDate(value?.from, dateRange.from) && isSameDate(value?.to, dateRange.to),
        get precedingDateRange() {
            if (!dateRange.from || !dateRange.to) return
            const difference = differenceInCalendarDays(dateRange.to, dateRange.from)
            const to = endOfDay(subDays(dateRange.from, 1))
            const from = startOfDay(subDays(to, difference))
            return DateRange({ from, to })
        },
    }
}

export const getResolution = (from, to) => {
    const day = 1000 * 60 * 60 * 24
    const numberOfDays = Math.round((to - from) / day)
    if (numberOfDays > 182) {
        let months = (to.getFullYear() - from.getFullYear()) * 12
        months -= from.getMonth()
        months += to.getMonth()
        return months
    } else if (numberOfDays > 30) {
        return Math.round((to - from) / (7 * day))
    } else {
        return numberOfDays
    }
}

export const abortAndCreateNewController = currentController => {
    if (currentController) currentController.abort()
    return new AbortController()
}

export const buildSuggestionId = suggestion =>
    suggestion.field
        ? `${suggestion.field}-${suggestion.label}`
        : `${suggestion.match}-${suggestion[suggestion.match]}`

export const formatSearchSuggestion = (searchResult, searchTerm, locale, searchLimit) => {
    const groupByMatch = (result = { results: [] }, searchTerm = '', locale, searchLimit) => {
        const fields = [...new Set(result.results?.map(item => item.match))]
        const hitCountString = result.hit_count < searchLimit ? result.hit_count : searchLimit + '+'

        const all = {
            match: 'all',
            label: locale('proAnalytics.search.termEverywhere', {
                searchTerm,
                hitCount: hitCountString,
            }),
            searchTerm,
            hitCount: result.hit_count,
            value: `all.${searchTerm}`,
        }
        const SEARCH_FIELDS = getSearchFields(locale)
        const groupedResult = fields.reduce((grouped, field) => {
            const fieldMatch = result.results.filter(item => item.match === field)
            const fieldName = O(SEARCH_FIELDS)
                .find(descriptor => descriptor.key === field)
                ?.valueOf().label
            const suggestions = distinct(fieldMatch, (item1, item2) => item1[field] === item2[field])
                .slice(0, 5)
                .map(suggestion => ({
                    label: suggestion[suggestion.match],
                    searchTerm,
                    value: buildSuggestionId(suggestion),
                    ...suggestion,
                }))
            const hitCount = suggestions.length < searchLimit ? suggestions.length : searchLimit + '+'
            let label = ''
            if (hitCount !== undefined)
                label = locale('proAnalytics.search.termInColumnWithHitCount', {
                    searchTerm,
                    fieldName,
                    hitCount,
                })
            else
                label = locale('proAnalytics.search.termInColumn', {
                    searchTerm,
                    fieldName,
                })

            const value = `${field}-${label}`
            grouped.push({ group: field, field, value, searchTerm, label, hitCount, suggestions })

            return grouped
        }, [])
        return {
            searchTerm,
            hitCount: result.hit_count,
            suggestions: searchTerm.length ? [all, ...groupedResult] : [],
        }
    }
    return groupByMatch(searchResult, searchTerm, locale, searchLimit)
}

export const filterOnlineMediaCosts = (performanceData, media) => {
    const costs = performanceData?.publicationPerformance
    const printMedia = media.find(group => group.key === 'print')?.children || []
    const filtered = costs?.filter(
        item => !printMedia.some(medium => medium.key === item.mediumId.toString())
    )
    return { ...performanceData, publicationPerformance: filtered }
}

export const filterRelevantCosts = (performanceData, action) => {
    const costs = performanceData?.publicationPerformance
    const filtered = costs?.filter(item => item[action] !== 0)
    return { ...performanceData, publicationPerformance: filtered }
}

/*export const getKpiStatistics = (performanceData = { publicationPerformance: [] }) => {
    return performanceData.publicationPerformance.reduce(
        (kpis, item) => {
            kpis.views += item.views || 0
            kpis.clicks += item.clicks || 0
            kpis.applications += item.applications || 0
            kpis.deviceRatio.total += item.viewsWeb + item.viewsMobile
            kpis.deviceRatio.web += item.viewsWeb
            kpis.deviceRatio.mobile += item.viewsMobile
            kpis.deviceRatio.percentWeb = (kpis.deviceRatio.web / kpis.deviceRatio.total) * 100
            kpis.deviceRatio.percentMobile = 100 - kpis.deviceRatio.percentWeb
            return kpis
        },
        {
            views: 0,
            clicks: 0,
            applications: 0,
            deviceRatio: { total: 0, web: 0, mobile: 0 },
            pits: performanceData.pitsToday,
            jobs: distinct(
                performanceData.publicationPerformance,
                (item1, item2) => item1.stelleId === item2.stelleId
            ).length,
            costsTotal: performanceData.costsTotal,
            activePublications: performanceData.activePublications,
            dailyCosts: performanceData.dailyCosts
        }
    )
}*/

export const calculateDeviceRatioKPIs = (publicationPerformance = []) =>
    publicationPerformance.reduce(
        (deviceRatio, item) => {
            deviceRatio.total += item.viewsWeb + item.viewsMobile
            deviceRatio.web += item.viewsWeb
            deviceRatio.mobile += item.viewsMobile
            deviceRatio.percentWeb = (deviceRatio.web / deviceRatio.total) * 100
            deviceRatio.percentMobile = 100 - deviceRatio.percentWeb
            return deviceRatio
        },
        { total: 0, web: 0, mobile: 0 }
    )

// Do NOT use subYears/addYears or subMonths/addMonths here. For the leap year it will give the wrong value
export const firstSelectableDate = subDays(startOfDay(new Date()), 365 * 2)
export const lastSelectableDate = endOfDay(addDays(firstSelectableDate, 365 * 2))

/**
 * Checks if given date range does not violate the constraint.
 * If given dateRange is valid, returns the same object, otherwise new date range object with adjusted dates.
 * @param {{from: Date, to: Date}} dateRange
 * @param {Object} context View context
 * return {{from: Date, to: Date}}
 */
export const validateDateRange = (dateRange, defaultDateRange) => {
    let result = dateRange
    if (!dateRange) result = defaultDateRange
    else if (dateRange.from < firstSelectableDate || dateRange.to > lastSelectableDate) {
        result = {
            from: dateRange.from < firstSelectableDate ? firstSelectableDate : dateRange.from,
            to: dateRange.to > lastSelectableDate ? lastSelectableDate : dateRange.to,
        }
    }
    return result
}

/**
 * This is related to https://prospective.myjetbrains.com/youtrack/issue/PA-902
 * We can't use the dictionary directly, because it contains multiple entries with the same labels. In the filter
 * we need to display every label once, but in the url params and the statistics endpoints we use all IDs associated
 * with selected labels.
 * FieldOfActivity abstracts the logic responsible for grouping field of activity by label or actually by name;
 * and for translating ids into labels and vice versa.
 * @param {Array} [dictionary] Original field of activity dictionary
 * @return {{
 *     fieldsOfActivityByLabel: { label: string, name: string, count: number, ids: [] },
 *     getValuesByIds: function(ids: string[]): string[],
 *     getIdsByValues: function(values: string[]): string[]
 * }}
 */
export const FieldsOfActivity = memoizeLast((dictionary = []) => {
    const fieldsOfActivityByLabel = dictionary.reduce((result, entry) => {
        let group = result.find(item => item.name === entry.name)
        if (!group) {
            group = { label: entry.label, name: entry.name, count: 0, ids: [] }
            result.push(group)
        }
        group.count = group.count + entry.count
        group.ids = group.ids.concat(entry.id)
        group.value = entry.name
        return result
    }, [])

    const memoizationScopeValuesByIds = { lastArguments: [], lastResult: undefined }
    const getValuesByIds = memoizeLastScoped(
        (ids = []) => {
            return ids
                .map(id => fieldsOfActivityByLabel.find(entry => entry.ids.includes(id)))
                .filter(entry => entry)
                .map(entry => entry.value)
                .reduce(deduplicate, [])
        },
        haveSameElements,
        memoizationScopeValuesByIds
    )

    const getIdsByValues = (values = []) =>
        values
            .map(value => fieldsOfActivityByLabel.find(entry => entry.value === value))
            .filter(entry => entry)
            .map(entry => entry.ids)
            .flat()

    return { fieldsOfActivityByLabel, getValuesByIds, getIdsByValues }
})

// TODO: Create a reusable function
export const getPublicationDates = (publications = [], locale) => {
    if (publications.length !== 0) {
        const dates = publications.reduce((obj, item) => {
            if (isDateValid(item['bis'])) obj.push(item['bis'])
            if (isDateValid(item['von'])) obj.push(item['von'])
            return obj
        }, [])

        const minDate = dates.reduce((a, b) => (a < b ? a : b))
        const maxDate = dates.reduce((a, b) => (a > b ? a : b))

        if (!locale) return { minDate, maxDate }
        return `${locale('dateFormatter', new Date(minDate))} - ${locale('dateFormatter', new Date(maxDate))}`
    }

    return '-'
}
