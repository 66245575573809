import { BusinessObject, Value } from '@structures/business_object.js'
import { Order } from '@structures/order.js'

const parseDate = dtoValue => (dtoValue ? new Date(dtoValue) : undefined)

const JobAdDescriptor = {
    jobId: Value,
    state: Value,
    jobTitle: Value,
    agencyReference: Value,
    additionalInformation: Value,
    recruiterId: Value,
    ownerId: Value,
    creatorId: Value,
    node: Value,
    costCenter: Value,
    costCenterId: Value,
    modificationDate: Value,
    creationDate: Value,
    publicationLanguages: Value,
    difficulty: Value,
    locationCity: Value,
    locationCountry: Value,
    locationPlaceId: Value,
    locationRegion: Value,
    locationPostalCode: Value,
    locationBbox: Value,
    locationLabel: Value,
    salaryUnit: Value,
    salaryCurrency: Value,
    salaryMin: Value,
    salaryMax: Value,
    workplaceModel: Value,
    employmentType: Value,
    workloadMin: Value,
    workloadMax: Value,
    fieldOfActivity: Value,
    externalJobUrl: Value,
    companyVideo: Value,
    orders: Value,
    dto: Value,
}

const fromDTO = dto =>
    Job({
        jobId: dto.job_id,
        state: dto.state,
        jobTitle: dto.title,
        agencyReference: dto.agency_reference,
        additionalInformation: dto.additional_information,
        recruiterId: dto.recruiter_id,
        ownerId: dto.owner_id,
        creatorId: dto.creator_id,
        node: dto.node,
        costCenter: dto.cost_center,
        costCenterId: dto.cost_center_id,
        modificationDate: parseDate(dto.modification_date),
        creationDate: parseDate(dto.created_at),
        publicationLanguages: dto.languages,
        difficulty: dto.difficulty,
        locationCity: dto.working_location.city,
        locationCountry: dto.working_location.country,
        locationPlaceId: dto.working_location.place_id,
        locationRegion: dto.working_location.region,
        locationPostalCode: dto.working_location.zip,
        locationBbox: dto.working_location.bbox,
        salaryUnit: dto.salary?.unit?.id,
        salaryCurrency: dto.salary?.currency?.id,
        salaryMin: dto.salary?.min,
        salaryMax: dto.salary?.max,
        workplaceModel: dto.workplace_model?.id,
        workloadMin: dto.workload?.min,
        workloadMax: dto.workload?.max,
        employmentType: dto.employment_type?.id,
        fieldOfActivity: dto.field_of_activity?.id,
        companyVideo: dto.company_video || undefined,
        orders: dto.orders.map(Order.fromDTO),
        externalJobUrl: dto.external_url,
        dto,
    })

const toDTO = jobAd => {
    const dto = {
        job_id: jobAd.jobId,
        state: jobAd.state,
        title: jobAd.jobTitle,
        internal_title: jobAd.jobTitle,
        working_location: {},
    }

    if (jobAd.agencyReference) dto.agency_reference = jobAd.agencyReference
    if (jobAd.additionalInformation) dto.additional_information = jobAd.additionalInformation
    if (jobAd.recruiterId) dto.recruiter_id = jobAd.recruiterId
    if (jobAd.ownerId) dto.owner_id = jobAd.ownerId
    if (jobAd.creatorId) dto.creator_id = jobAd.creatorId
    if (jobAd.node) dto.node = jobAd.node
    if (jobAd.costCenter) dto.cost_center = jobAd.costCenter
    if (jobAd.costCenterId) dto.cost_center_id = jobAd.costCenterId
    if (jobAd.modificationDate) dto.modification_date = jobAd.modificationDate
    if (jobAd.creationDate) dto.creation_date = jobAd.creationDate
    if (jobAd.publicationLanguages) dto.languages = jobAd.publicationLanguages
    if (jobAd.difficulty) dto.difficulty = jobAd.difficulty
    if (jobAd.locationCity) dto.working_location.city = jobAd.locationCity
    if (jobAd.locationCountry) dto.working_location.country = jobAd.locationCountry
    if (jobAd.locationPlaceId) dto.working_location.place_id = jobAd.locationPlaceId
    if (jobAd.locationRegion) dto.working_location.region = jobAd.locationRegion
    if (jobAd.locationPostalCode) dto.working_location.zip = jobAd.locationPostalCode
    if (jobAd.locationBbox) dto.working_location.bbox = jobAd.locationBbox
    if (jobAd.salaryMin || jobAd.salaryMax || jobAd.salaryUnit || jobAd.salaryCurrency) dto.salary = {}
    if (jobAd.salaryUnit) dto.salary.unit = { id: jobAd.salaryUnit }
    if (jobAd.salaryCurrency) dto.salary.currency = { id: jobAd.salaryCurrency }
    if (jobAd.salaryMin) dto.salary.min = jobAd.salaryMin
    if (jobAd.salaryMax) dto.salary.max = jobAd.salaryMax
    if (jobAd.workplaceModel) dto.workplace_model = { id: jobAd.workplaceModel }
    if (jobAd.workloadMin || jobAd.workloadMax) dto.workload = {}
    if (jobAd.workloadMin) dto.workload.min = jobAd.workloadMin.toFixed()
    if (jobAd.workloadMax) dto.workload.max = jobAd.workloadMax.toFixed()
    if (jobAd.employmentType) dto.employment_type = { id: jobAd.employmentType }
    if (jobAd.fieldOfActivity) dto.field_of_activity = { id: jobAd.fieldOfActivity }
    if (jobAd.externalJobUrl) dto.external_url = jobAd.externalJobUrl
    if (jobAd.companyVideo) dto.company_video = jobAd.companyVideo
    return dto
}

export const Job = BusinessObject(JobAdDescriptor).extend(jobAd => ({
    toDTO: () => toDTO(jobAd),
    getOrder: orderId => (orderId ? jobAd.orders?.find(order => order.id === orderId) : jobAd.orders?.at(0)),
    getActiveOrder: () => jobAd.orders?.find(order => order.order_state === Order.STATE_ACTIVE),
    isActive: jobAd.state === Job.STATE_ACTIVE,
    publicationLanguage: jobAd.publicationLanguages?.at(0),
}))

Job.fromDTO = fromDTO
Job.STATE_ACTIVE = 'ACTIVE'
Job.STATE_EXPIRED = 'EXPIRED'
Job.STATE_INACTIVE = 'INACTIVE'
Job.STATE_DRAFT = 'DRAFT'
