import { useView } from '@lib/view_context/view_context_hooks.js'
import { UserSession } from '@login/user-session.js'
import { ModuleController } from '@modules/module_controller.js'
import { stateOf } from '@prospective/process-router'
import { UserDetails } from '@modules/user/user.js'
import { valueOf } from '@utils/process_hooks.js'
import { ApplicationContext } from '@views/application/application.context.js'

export const UserSessionProcess = () => {
    const userSession = stateOf(UserSession)
    const user = valueOf(UserDetails)
    const profilePanelView = useView(ApplicationContext)

    profilePanelView.update(fields => {
        fields.authenticated.value = userSession.status === 'AUTHENTICATED' || false
        fields.salutation.value = user?.salutation
        fields.firstName.value = user?.firstName
        fields.lastName.value = user?.lastName
        fields.fullName.value = user?.fullName
        fields.gender.value = user?.gender
        fields.profileImage.value = user?.profilePicture
        fields.email.value = user?.email
        fields.logIn.onTrigger = ModuleController.login
        fields.logOut.onTrigger = ModuleController.logout
        fields.password.onTrigger = () => window.location.replace(userSession.authConfig?.passwordChangeUrl)
    })
}
UserSessionProcess.label = 'User Session'
