import { ArrayTarget, ConsoleTarget, Log, LogLevel, TemplateMessageFormatter } from '@intermattory/logging'
import { LogzIOTarget } from '@modules/logging/logzio_log_target.js'
import { memoizeScoped } from '@prospective/pms-js-utils'
import { CONFIG } from '@configuration/config_variables.js'
import { JobBoosterServiceError } from '@services/job_booster_service_error.js'

window.log = []
const consoleFormatter = (entry, formatted) =>
    `#${formatted.logNumber} [${formatted.level}][${formatted.time}] ${formatted.source}: ${formatted.message}${
        entry.error ? ' ' + formatted.errorName + ' ' + formatted.errorMessage + ' ' + formatted.stackTrace : ''
    }`
const consoleTarget = ConsoleTarget(TemplateMessageFormatter(consoleFormatter))
export const arrayTarget = ArrayTarget()
arrayTarget.subscribe(entry => window.log.push(entry))

export function startLogger() {
    log.start()
    const logger = Logger('Logger', CATEGORIES.MAIN)
    logger.info('Environment:', CONFIG.environment)
}

const abortErrorFilter = logEntry => !(logEntry.error?.type === JobBoosterServiceError.ABORT_ERROR)
const localizationFilter = logEntry => !(logEntry.source === 'Localization')

const logConfig = {
    development: {
        logTargets: [
            {
                target: consoleTarget,
                level: LogLevel.DEBUG,
                filter: abortErrorFilter,
            },
            {
                target: arrayTarget,
                level: LogLevel.ALL,
            },
        ],
    },
    stage: {
        logTargets: [
            {
                target: consoleTarget,
                level: LogLevel.ALL,
                filter: logEntry => abortErrorFilter(logEntry) && localizationFilter(logEntry)
            },
            {
                target: arrayTarget,
                level: LogLevel.ALL,
            },
            {
                target: LogzIOTarget,
                level: LogLevel.WARN,
                filter: abortErrorFilter,
            },
        ],
    },
    production: {
        logTargets: [
            {
                target: consoleTarget,
                level: LogLevel.WARN,
                filter: abortErrorFilter,
            },
            {
                target: arrayTarget,
                level: LogLevel.ALL,
            },
            {
                target: LogzIOTarget,
                level: LogLevel.WARN,
                filter: abortErrorFilter,
            },
        ],
    },
}
const environment =
    CONFIG.environment === 'stage' ? 'stage' : CONFIG.environment === 'development' ? 'development' : CONFIG.environment

const log = Log(logConfig[environment])

/** @function
 * @param {string | function} source
 * @param {string} [category]
 * @return {Object} logger object
 *  */
export const Logger = memoizeScoped((source, category) =>
    log.Logger(source, {
        category,
        environment,
        hostname: window.location.hostname,
        browser: window.navigator.userAgent,
        clientSessionId: Date.now(),
    })
)

export const CATEGORIES = {
    MAIN: 'JobBooster Main',
    PLUGINS: 'Plugins',
}

startLogger()
