import { SettingsContext } from '@views/settings/settings.context'
import { PluginManager } from '@modules/plugins/plugin_manager'
import { PasswordProcess } from '@views/settings/password/password.process'
import { ReportListProcess } from '@views/settings/reports/report_list.process'
import { CompanyInfoProcess } from '@views/settings/company_info/company_info.process'
import { NotificationsProcess } from '@views/settings/notifications/notifications.process'
import { Process, stateOf, useActiveChildProcess } from '@prospective/process-router'
import { useView } from '@lib/view_context/view_context_hooks'
import { InvoicesProcess } from '@views/settings/invoices/invoices.process'
import { CATEGORIES, Logger } from '@modules/logging/logger'
import { Authorization } from '@modules/authorization/authorization.js'
import { createErrorStatus, getErrorStructure } from '@utils/request_statuses.js'
import { Localization } from '@lib/i18n/localization.js'
import { ModuleController } from '@modules/module_controller.js'
import { useRemoteDataStream } from '@utils/process_hooks.js'
import { RemoteData } from '@lib/remote_data/remote-data.js'

const logger = Logger('Settings', CATEGORIES.MAIN)
export const SettingsProcess = Process(({ process }) => {
    const view = useView(SettingsContext)
    const { features } = stateOf(PluginManager)
    const { permissions } = stateOf(Authorization)
    const { locale } = stateOf(Localization)
    const [, dictionariesState] = useRemoteDataStream(ModuleController.getDictionaries, RemoteData.queued())
    const [, hierarchyState] = useRemoteDataStream(ModuleController.getHierarchyNode, RemoteData.queued())
    const [getPermissions, permissionsState] = useRemoteDataStream(ModuleController.getPermissions, RemoteData.queued())
    const [getProAnalyticsPermissions, getProAnalyticsPermissionsState] = useRemoteDataStream(
        ModuleController.getProAnalyticsPermissions,
        RemoteData.queued()
    )

    const pluginChildProcesses = features.settings?.childProcesses.inject() || []
    const childProcesses = [
        PasswordProcess,
        ReportListProcess,
        NotificationsProcess,
        CompanyInfoProcess,
        InvoicesProcess,
        ...pluginChildProcesses,
    ]

    const [activeChildProcess, setActiveChildProcess] = useActiveChildProcess(...childProcesses)

    const pluginProcesses =
        features.settings?.processes?.inject().map(subProcess => subProcess({ setActiveChildProcess })) || []

    view.update((fields, errors, validation) => {
        fields.reports.onTrigger = () => setActiveChildProcess(ReportListProcess)
        fields.notifications.onTrigger = () => setActiveChildProcess(NotificationsProcess)
        fields.companyInfo.onTrigger = () => setActiveChildProcess(CompanyInfoProcess)
        fields.invoices.onTrigger = () => setActiveChildProcess(InvoicesProcess)
        fields.tasks.dictionary = [
            { label: locale('loadingDictionaries'), ...dictionariesState },
            { label: locale('companySelectorContentMessage'), ...hierarchyState },
            { label: locale('loadingPermissions'), ...permissionsState },
        ]

        pluginProcesses.forEach(pluginSubProcess => {
            if (pluginSubProcess.view) pluginSubProcess.view({ fields, errors, validation })
        })

        errors.noReadPermissionsError =
            permissions?.settings?.permissions.read !== true
                ? getErrorStructure(locale, createErrorStatus(locale('settings.permissionsWarning')))
                : undefined
    })

    return async () => {
        await ModuleController.login()
        process.ready()

        await getPermissions()
        await getProAnalyticsPermissions()

        try {
            pluginProcesses.forEach(pluginSubProcess => {
                if (pluginSubProcess.update) pluginSubProcess.update()
            })
        } catch (error) {
            logger.warn.withError(error, 'One of the plugins threw an error when updating Settings process')
        }
    }
})

SettingsProcess.label = 'Settings'
