import { useCallback, useEffect, useMemo, useState } from 'react'
import { Authorization } from '@modules/authorization/authorization.js'
import { Alert, Col, Form, Row, Tree } from 'antd'
import { O } from '@prospective/pms-js-utils'

export default function PermissionsViewer({ className = '', ...props }) {
    const [permissions, setPermissions] = useState({})
    const [proAnalyticsPermissions, setProAnalyticsPermissions] = useState({})
    const [roles, setRoles] = useState(null)
    const [isAdminUser, setIsAdminUser] = useState(null)

    const formatPermissionNode = (node, key, keyChain = [key]) => {
        const children = O(node?.permissions).keys()
            .map(key => ({
                key: [...keyChain, key].join('.'),
                title: key,
                checkable: true
            }))
            .concat(...O(node)
                .excluding('permissions')
                .map((node, key) => formatPermissionNode(node, key, [...keyChain, key]))
                .values()
            )
        return ({
            key: keyChain.join('.'),
            title: key,
            checkable: false,
            children
        })
    }

    const getSelectedPermissionNodes = (node, key, keyChain = [key]) => {
        const selected = []
        if (node.permissions?.read) selected.push([...keyChain, 'read'].join('.'))
        if (node.permissions?.write) selected.push([...keyChain, 'write'].join('.'))
        if (node.permissions?.execute) selected.push([...keyChain, 'execute'].join('.'))
        if (node.permissions?.delete) selected.push([...keyChain, 'delete'].join('.'))

        const childNodesSelection = O(node)
            .excluding('permissions')
            .map((node, key) => getSelectedPermissionNodes(node, key, [...keyChain, key]))
            .values()
        return [...selected, ...childNodesSelection.flat()]
    }

    const updatePermissions = (selectedKeys, node, key, keyChain = [key]) => ({
        permissions: O(node.permissions)
            .map((value, permission) => selectedKeys.includes([...keyChain, permission].join('.')))
            .valueOf(),
        ...O(node)
            .excluding('permissions')
            .map((childNode, key) => updatePermissions(selectedKeys, childNode, key, [...keyChain, key]))
            .valueOf()
    })

    const permissionsTree = O(permissions)
        .map((node, key) => formatPermissionNode(node, key))
        .values()

    const proAnalyticsPermissionsTree = O(proAnalyticsPermissions)
        .map((node, key) => formatPermissionNode(node, key))
        .values()

    const permissionsValues= O(permissions)
        .map((node, key) => getSelectedPermissionNodes(node, key))
        .values()
        .flat()

    const proAnalyticsPermissionsValues= O(proAnalyticsPermissions)
        .map((node, key) => getSelectedPermissionNodes(node, key))
        .values()
        .flat()

    const onAuthorizationUpdate = ({permissions, proAnalyticsPermissions, roles, isAdminUser}) => {
        setPermissions(permissions)
        setProAnalyticsPermissions({ proAnalytics: proAnalyticsPermissions })
        setRoles(roles)
        setIsAdminUser(isAdminUser)
    }

    const onCheck = useCallback(items => {
        const newPermissions = O(permissions)
            .map((node, key) => updatePermissions(items, node, key))
            .valueOf()
        Authorization.permissions = newPermissions
    }, [permissions])

    useEffect(() => {
        Authorization.subscribe(onAuthorizationUpdate)
        onAuthorizationUpdate(Authorization)
        return () => {
            Authorization.unsubscribe(onAuthorizationUpdate)
        }
    }, [])

    return (
        <div className={`permissions-viewer ${className}`} {...props}>
            <Form.Item label="Logged in as">{isAdminUser ? 'Admin user' : 'Non-admin user'}</Form.Item>
            <Form.Item label="Roles">{roles}</Form.Item>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="Permissions">
                        {permissionsTree.length && <Tree
                                treeData={permissionsTree}
                                checkedKeys={permissionsValues}
                                onCheck={onCheck}
                                height={500}
                                showLine={true}
                                checkable={true}
                                selectable={false}
                                defaultExpandParent={true}
                                defaultExpandedKeys={permissionsTree.map(node => node.key)}
                            />}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Permissions">
                        {permissionsTree.length && <Tree
                            treeData={proAnalyticsPermissionsTree}
                            checkedKeys={proAnalyticsPermissionsValues}
                            onCheck={onCheck}
                            height={500}
                            showLine={true}
                            checkable={true}
                            selectable={false}
                            defaultExpandParent={true}
                            defaultExpandedKeys={proAnalyticsPermissionsTree.map(node => node.key)}
                        />}
                    </Form.Item>
                </Col>
            </Row>
            <Alert message="Changing the permissions above has no effect on the permissions stored in the database. This is just a debug tool to test the front-end implementation" type="info" showIcon />
        </div>
    )
}
