import { Localization } from '@lib/i18n/localization.js'
import { Authorization } from '@modules/authorization/authorization'
import { View } from '@lib/view_context/view-context'

const ReportListMetadata = (locale, permissions) => {
    return {
        title: { label: locale('reports') },
        newReport: {
            label: locale('reports.newReport'),
            actions: ['onTrigger'],
            disabled: !permissions?.reports?.permissions?.write,
        },
        editReport: {
            label: locale('reports.editReport'),
            actions: ['onTrigger'],
            disabled: !permissions?.reports?.permissions?.write,
        },
        deleteReport: {
            label: locale('reports.deleteReport'),
            actions: ['onTrigger'],
            disabled: !permissions?.reports?.permissions?.write,
        },
        reports: {
            label: locale('reports'),
            visible: permissions?.reports?.permissions?.read,
        },
        insufficientPermissionsMessage: {
            label: locale('insufficientPermissions'),
            value: locale('reports.insufficientPermissions'),
            visible: !permissions?.reports?.permissions?.read,
        },
        reportsRequestStatus: {
            label: locale('status'),
        },
        reportDeleteStatus: {
            label: locale('status'),
        },
        // Used for insufficient permissions
        problemDetails: {},
    }
}

export const ReportListContext = View.observe(Localization, Authorization).update((localization, authorization) =>
    ReportListMetadata(localization.text, authorization.proAnalyticsPermissions)
)
