
import { Modal, message } from 'antd'
import { Localization } from '@lib/i18n/localization'

export const unexpectedErrorModal = logNumber => {
    Modal.error({
        title: 'Ooops...',
        content: (
            <>
                <p>{Localization.locale('unexpectedError', { logNumber })}</p>
                <p>{Localization.locale('refreshRequest')}</p>
            </>
        ),
    })
}

export const infoModal = (message, title = 'Info') => {
    Modal.info({
        title,
        content: (
            <>
                <p>{message}</p>
            </>
        ),
    })
}

export const warningModal = (message, title = 'Info') => {
    Modal.warn({
        title,
        content: (
            <>
                <p>{message}</p>
            </>
        ),
    })
}

/**
 * @param {ModalFuncProps} options Ant Modal function properties
 */
export const errorModal = options => {
    Modal.error(options)
}

export const infoNotification = content => {
    message.info(content).then()
}

const MESSAGE_DURATION = 7
export const successNotification = (content, cb) => message.success(content, [MESSAGE_DURATION]).then(cb)
export const errorNotification = (content, cb) => message.error(content, [MESSAGE_DURATION]).then(cb)

export const successModal = options => {
    Modal.success(options)
}
