import { useContext, useEffect, useRef, useState } from 'react'
import { ProAnalyticsContext } from '@views/pro_analytics/pro_analytics.context'
import { Badge, Button, Col, Row, Select, Tag, TreeSelect, Typography, Tooltip } from 'antd'
import { ProAnalyticsSearch } from '../pro_analytics_dashboard/pro_analytics_search'
import { haveSameElements } from '@prospective/pms-js-utils'
import { Remove, ExpandDiagonal, DiagonalArrow } from '@assets/icons/icons.js'
import { DatePicker } from '@components/elements/datepicker/datepicker'
import { useCustomerConfig } from '@modules/customer_config/customer_config'
import { LocaleContext } from '@lib/i18n/localization_provider.jsx'
import { useViewModel } from '@lib/view_context/view-model.js'
import { firstSelectableDate, lastSelectableDate } from '@views/pro_analytics/pro_analytics_utils.js'
import Icon from '@ant-design/icons'
import './pro_analytics_filters.scss'

const getDefaultStartDate = () => {
    const today = new Date()
    return new Date(today.getFullYear(), today.getMonth() - 1)
}
const getDefaultEndDate = () => new Date()

const isSameDay = (date1, date2) =>
    date1?.getFullYear() === date2?.getFullYear() &&
    date1?.getMonth() === date2?.getMonth() &&
    date1?.getDate() === date2?.getDate()

function tagRender(props) {
    const { label, closable, onClose } = props
    const onPreventMouseDown = event => {
        event.preventDefault()
        event.stopPropagation()
    }

    return (
        <Tag onMouseDown={onPreventMouseDown} onClose={onClose} closable={closable}>
            <Tooltip placement="bottom" title={label}>
                <Typography.Text ellipsis={{ title: label }} style={{ width: 'calc(100% - 20px)' }} title={label}>
                    {label}
                </Typography.Text>
            </Tooltip>
        </Tag>
    )
}

const maxTagRender = props => {
    const TAG_DISPLAY_NUMBER = 5
    const maxTagLabel = `+ ${props.length}...`
    const labels = props.map((prop, index) => {
        if (index <= TAG_DISPLAY_NUMBER)
            return (
                <span key={index} className="tag-tooltip-label">
                    {prop.label}
                </span>
            )
        if (index === props.length - 1)
            return (
                <span key={index} className="tag-tooltip-label">
                    ...and {props.length - 5} more
                </span>
            )
    })

    return (
        <Tooltip placement="bottom" title={labels}>
            <Typography.Text title={maxTagLabel}>{maxTagLabel}</Typography.Text>
        </Tooltip>
    )
}

const filterInactiveRecruiters = recruiters => {
    return recruiters?.filter(recruiter => !recruiter.name.includes('zzz'))
}

export const ProAnalyticsFilters = function ({ className = '', classes, ...props }) {
    const container = useRef(null)
    const [isCollapsed, setIsCollapsed] = useState(false)
    const { locale } = useContext(LocaleContext)
    const viewModel = useViewModel(ProAnalyticsContext)
    const customerConfig = useCustomerConfig()
    const showPmsFilter = viewModel.pmsFilters.visible

    useEffect(() => {
        if (viewModel.dashboardMessage.value) setIsCollapsed(false)
    }, [viewModel])

    function getSelectedDateRange(startDate = getDefaultStartDate(), endDate = getDefaultEndDate()) {
        return viewModel.dateRangeFilter.dictionary.find(entry => {
            const { from, to } = entry.getDateRange()
            return isSameDay(from, startDate) && isSameDay(to, endDate)
        })
    }

    const hierarchyFilterProps = {
        treeData: viewModel.organizationStructureFilter.dictionary,
        value: viewModel.organizationStructureFilter.value,
        treeCheckable: true,
        showCheckedStrategy: TreeSelect.SHOW_ALL,
        placeholder: viewModel.organizationStructureFilter.placeholder,
        allowClear: false,
        maxTagCount: 'responsive',
        label: viewModel.organizationStructureFilter.label,
        // treeDefaultExpandAll: true,
        /*treeDefaultExpandedKeys: viewModel.organizationStructureFilter.dictionary?.map(
            (node) => node.key
        ),*/
        showSearch: true,
        style: { width: '100%' },
        className: 'hierarchy-filter',
        filterTreeNode: (value, option) => option.label?.toLowerCase().indexOf(value.toLowerCase()) > -1,
        getPopupContainer: () => container.current,
        onChange: value => viewModel.organizationStructureFilter.setValue(value).onChange(value),
        tagRender: tagRender,
        maxTagPlaceholder: props => maxTagRender(props, '.hierarchy-filter'),
        // popupMatchSelectWidth: false,
        dropdownStyle: { width: 400 },
    }

    const recruitersFilterProps = {
        mode: 'multiple',
        options: filterInactiveRecruiters(viewModel.recruiterFilter.dictionary),
        disabled: viewModel.recruiterFilter.disabled,
        value: viewModel.recruiterFilter.value,
        placeholder: viewModel.recruiterKeyword.value || viewModel.recruiterFilter.placeholder,
        maxTagCount: 'responsive',
        // optionLabelProp: 'name',
        allowClear: false,
        loading: viewModel.recruiterFilter.isLoading,
        style: { width: '100%' },
        className: 'recruiters-filter',
        tagRender: tagRender,
        maxTagPlaceholder: props => maxTagRender(props, '.recruiters-filter'),
        filterOption: (value, option) => option.label.toLowerCase().indexOf(value.toLowerCase()) > -1,
        onChange: value => {
            viewModel.recruiterFilter.setValue(value)
        },
        onDropdownVisibleChange: open => {
            if (open) viewModel.recruiterFilter.getDictionary()
        },
        getPopupContainer: () => container.current,
    }

    const mediaFilterProps = {
        treeCheckable: true,
        treeDefaultExpandAll: true,
        treeData: viewModel.mediaFilter.dictionary,
        value: viewModel.mediaFilter.value,
        disabled: viewModel.mediaFilter.disabled,
        onChange: value => {
            viewModel.mediaFilter.setValue(value).onChange(value)
        },
        placeholder: viewModel.mediaFilter.placeholder,
        maxTagCount: 'responsive',
        allowClear: false,
        style: { width: '100%' },
        className: 'media-filter',
        filterTreeNode: (value, option) => option.label.toLowerCase().indexOf(value.toLowerCase()) > -1,
        loading: viewModel.mediaFilter.isLoading,
        onDropdownVisibleChange: open => {
            if (open) viewModel.mediaFilter.getDictionary()
        },
        getPopupContainer: () => container.current,
        // optionLabelProp: 'name',
        tagRender: tagRender,
        maxTagPlaceholder: props => maxTagRender(props, '.media-filter'),
    }

    const fieldOfActivityFilterProps = {
        mode: 'multiple',
        options: viewModel.fieldOfActivityFilter.dictionary,
        disabled: viewModel.fieldOfActivityFilter.disabled,
        value: viewModel.fieldOfActivityFilter.value,
        onChange: value => {
            viewModel.fieldOfActivityFilter.setValue(value)
        },
        placeholder: viewModel.fieldOfActivityFilter.placeholder,
        maxTagCount: 'responsive',
        allowClear: false,
        style: { width: '100%' },
        className: 'fields-of-activity-filter',
        filterOption: (value, option) => option.label?.toLowerCase().indexOf(value.toLowerCase()) > -1,
        loading: viewModel.fieldOfActivityFilter.isLoading,
        onDropdownVisibleChange: open => {
            if (open) viewModel.fieldOfActivityFilter.getDictionary()
        },
        getPopupContainer: () => container.current,
        optionLabelProp: 'name',
        tagRender: tagRender,
        maxTagPlaceholder: props => maxTagRender(props, '.fields-of-activity-filter'),
    }

    const industryFilterProps = {
        mode: 'multiple',
        options: viewModel.industryFilter.dictionary,
        disabled: viewModel.industryFilter.disabled,
        value: viewModel.industryFilter.value,
        onChange: value => {
            viewModel.industryFilter.setValue(value)
        },
        placeholder: viewModel.industryFilter.placeholder,
        maxTagCount: 'responsive',
        allowClear: true,
        style: { width: '100%' },
        className: 'industry-filter',
        filterOption: (value, option) => option.label.toLowerCase().indexOf(value.toLowerCase()) > -1,
        loading: viewModel.industryFilter.isLoading,
        onDropdownVisibleChange: open => {
            if (open) viewModel.industryFilter.getDictionary()
        },
        getPopupContainer: () => container.current,
        optionLabelProp: 'name',
        tagRender: tagRender,
        maxTagPlaceholder: props => maxTagRender(props, '.industry-filter'),
    }

    const atsFilterProps = {
        mode: 'multiple',
        options: viewModel.atsFilter.dictionary,
        disabled: viewModel.atsFilter.disabled,
        value: viewModel.atsFilter.value,
        onChange: value => {
            viewModel.atsFilter.setValue(value)
        },
        placeholder: viewModel.atsFilter.label,
        maxTagCount: 'responsive',
        allowClear: false,
        style: {
            width: '100%',
        },
        className: 'ats-filter',
        filterOption: (value, option) => option.label.toLowerCase().indexOf(value.toLowerCase()) > -1,
        loading: viewModel.atsFilter.isLoading,
        onDropdownVisibleChange: open => {
            if (open) viewModel.atsFilter.getDictionary()
        },
        getPopupContainer: () => container.current,
        optionLabelProp: 'name',
        tagRender: tagRender,
        maxTagPlaceholder: props => maxTagRender(props, '.ats-filter'),
    }

    const dateChangeHandler = value => {
        const [from, to] = value || []
        const dateRange = { from, to }
        viewModel.dateRangeFilter.setValue(dateRange)
    }

    const selectedDateRange =
        getSelectedDateRange(viewModel.dateRangeFilter.value?.from, viewModel.dateRangeFilter.value?.to) ||
        (viewModel.dateRangeFilter.value && {
            label: `${locale('dateFormatter.shortDate', viewModel.dateRangeFilter.value.from)} - ${locale(
                'dateFormatter.shortDate',
                viewModel.dateRangeFilter.value.to
            )}`,
            value: 'custom',
        })

    const isSameDateRange = (dateRange1, dateRange2) =>
        isSameDay(dateRange1?.from, dateRange2?.from) && isSameDay(dateRange1?.to, dateRange2?.to)
    const dateRangeFilterOptions = viewModel.dateRangeFilter.dictionary.map(item => (
        <Select.Option key={item.value}>{item.label}</Select.Option>
    ))
    const setFiltersNumber =
        Number(viewModel.textFilter.value !== viewModel.textFilter.default) +
        Number(!isSameDateRange(viewModel.dateRangeFilter.value, viewModel.dateRangeFilter.default)) +
        Number(!haveSameElements(viewModel.recruiterFilter.value, viewModel.recruiterFilter.default)) +
        Number(!haveSameElements(viewModel.mediaFilter.value, viewModel.mediaFilter.default)) +
        Number(!haveSameElements(viewModel.fieldOfActivityFilter.value, viewModel.fieldOfActivityFilter.default)) +
        Number(!haveSameElements(viewModel.industryFilter.value, viewModel.industryFilter.default)) +
        Number(!haveSameElements(viewModel.atsFilter.value, viewModel.atsFilter.default)) +
        Number(
            !haveSameElements(
                viewModel.organizationStructureFilter.value,
                viewModel.organizationStructureFilter.default
            )
        )

    if (selectedDateRange?.value === 'custom')
        dateRangeFilterOptions.push(
            <Select.Option key={selectedDateRange.value} className="pro-analytics-filters__custom-date-range-option">
                {selectedDateRange.label}
            </Select.Option>
        )
    className = className + ' ' + (isCollapsed ? 'collapsed' : 'expanded')

    return (
        <div
            ref={container}
            className={`pro-analytics-filters ${className} ${!showPmsFilter ? 'client' : ''}`}
            {...props}
        >
            <Row gutter={[24, 16]}>
                <Col
                    sm={{ span: 24, order: 2 }}
                    md={{ span: 24, order: 2 }}
                    xl={{ span: 11, order: 1 }}
                    xxl={{ span: 11, order: 1 }}
                    data-element="1-1"
                >
                    <ProAnalyticsSearch popupContainer={container} viewModel={viewModel} />
                </Col>
                <Col
                    sm={{ span: 24, order: 1 }}
                    md={{ span: 24, order: 1 }}
                    xl={{ span: 7, order: 3 }}
                    xxl={{ span: 7, order: 3 }}
                    data-element="1-3"
                >
                    <div className="pro-analytics-filters__filters">
                        <Button
                            shape="round"
                            style={{ width: '100%', fontSize: '1rem' }}
                            onClick={() => {
                                viewModel.resetFilters.onTrigger()
                                customerConfig.error = null
                            }}
                        >
                            <Badge
                                size="small"
                                color="#f5cc00"
                                count={setFiltersNumber}
                                style={{ color: '#000000', border: 'none', top: '3px' }}
                            >
                                <Icon style={{ marginRight: '0.2rem' }} component={() => <Remove />} />
                            </Badge>
                            <span style={{ marginLeft: '0.45rem' }}>{locale('proAnalytics.resetFilters')}</span>
                        </Button>
                        <Button
                            shape="round"
                            onClick={() => setIsCollapsed(!isCollapsed)}
                            style={{ width: '100%', fontSize: '1rem' }}
                        >
                            {isCollapsed ? locale('proAnalytics.moreFilters') : locale('proAnalytics.lessFilters')}{' '}
                            <Icon
                                style={{ fontSize: '10px', marginLeft: '0.45rem' }}
                                component={() =>
                                    isCollapsed ? (
                                        <ExpandDiagonal className="anticon-arrow-outlined" />
                                    ) : (
                                        <DiagonalArrow />
                                    )
                                }
                            />
                        </Button>
                    </div>
                </Col>
                <Col
                    sm={{ span: 24, order: 3 }}
                    md={{ span: 12, order: 2 }}
                    xl={{ span: 6, order: 2 }}
                    xxl={{ span: 6, order: 2 }}
                    data-element="1-2"
                >
                    <DatePicker.RangePicker
                        format={locale('proAnalytics.rangePickerFormat')}
                        locale={locale('antd', 'DatePicker')}
                        style={{ width: '100%' }}
                        onChange={dateChangeHandler}
                        disabledDate={current =>
                            current && (current < firstSelectableDate || current > lastSelectableDate)
                        }
                        value={[viewModel.dateRangeFilter.value?.from, viewModel.dateRangeFilter.value?.to]}
                        allowClear={false}
                        getPopupContainer={() => container.current}
                        presets={viewModel?.dateRangeFilter?.dictionary.map(entry => {
                            const { from, to } = entry.getDateRange()
                            return {
                                label: entry.label,
                                value: [from, to],
                            }
                        })}
                    />
                </Col>
                <Col
                    style={{ display: isCollapsed ? 'none' : undefined }}
                    sm={{ span: 24, order: 5 }}
                    md={{ span: 12, order: 5 }}
                    xl={{ span: 6, order: 5 }}
                    xxl={{ span: 5, order: 5 }}
                    data-element="2-1"
                >
                    <TreeSelect {...hierarchyFilterProps} placeholder={viewModel.organizationStructureFilter.label} />
                </Col>
                <Col
                    style={{ display: isCollapsed ? 'none' : undefined }}
                    sm={{ span: 24, order: 6 }}
                    md={{ span: 12, order: 6 }}
                    xl={{ span: 5, order: 6 }}
                    xxl={{ span: 6, order: 6 }}
                    data-element="2-2"
                >
                    <Select {...recruitersFilterProps} placeholder={locale('allRecruiters')} />
                </Col>
                <Col
                    style={{ display: isCollapsed ? 'none' : undefined }}
                    sm={{ span: 24, order: 7 }}
                    md={{ span: 12, order: 7 }}
                    xl={{ span: 6, order: 7 }}
                    xxl={{ span: 6, order: 7 }}
                    data-element="2-3"
                >
                    <TreeSelect {...mediaFilterProps} placeholder={locale('allMedia')} />
                </Col>
                {showPmsFilter && (
                    <Col
                        style={{ display: isCollapsed ? 'none' : undefined }}
                        sm={{ span: 24, order: 8 }}
                        md={{ span: 12, order: 8 }}
                        xl={{ span: 7, order: 8 }}
                        xxl={{ span: 7, order: 8 }}
                        data-element="2-4"
                    >
                        <Select {...fieldOfActivityFilterProps} placeholder={locale('allFieldsOfActivity')} />
                    </Col>
                )}
                {showPmsFilter && (
                    <Col
                        style={{ display: isCollapsed ? 'none' : undefined }}
                        sm={{ span: 24, order: 9 }}
                        md={{ span: 12, order: 9 }}
                        xl={{ span: 6, order: 9 }}
                        xxl={{ span: 5, order: 9 }}
                        data-element="3-1"
                    >
                        <Select {...industryFilterProps} placeholder={locale('allIndustries')} />
                    </Col>
                )}
                {showPmsFilter && (
                    <Col
                        style={{ display: isCollapsed ? 'none' : undefined }}
                        sm={{ span: 24, order: 9 }}
                        md={{ span: 12, order: 9 }}
                        xl={{ span: 5, order: 9 }}
                        xxl={{ span: 6, order: 9 }}
                        data-element="3-2"
                    >
                        <Select {...atsFilterProps} />
                    </Col>
                )}
            </Row>
        </div>
    )
}
