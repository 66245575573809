import { DateRange, deduplicate, distinct, haveSameElements, tryCatch } from '@prospective/pms-js-utils'
import { CATEGORIES, Logger } from '@lib/../../app/modules/logging/logger'
import { FieldsOfActivity, getDictionaryFields, getSearchFields } from '@views/pro_analytics/pro_analytics_utils.js'
import {
    abortAndCreateNewController,
    createErrorStatus,
    createPendingStatus,
    createSuccessStatus,
    STATUS_PENDING,
} from '@utils/request_statuses'
import { Localization } from '@lib/i18n/localization.js'
import { LOCALE_TAG } from '@lib/i18n/resources.js'
import { ProAnalyticsOrganizationFilter } from '@views/pro_analytics/pro_analytics_organization_filter'
import { PluginManager } from '@modules/plugins/plugin_manager'
import { Authorization } from '@modules/authorization/authorization.js'
import { UserSession } from '@login/user-session.js'
import { infoNotification } from '@components/modules/global_notifications/global_notifications.jsx'
import { stateOf, useCallback, useMemo, useMemoLast, useState } from '@prospective/process-router'
import { getDefaultDateRange } from '@views/pro_analytics/pro_analytics.context.js'
import JobBoosterService, { JobBoosterServiceError } from '@services/job_booster_service'

const logger = Logger('ProAnalyticsFilters', CATEGORIES.MAIN)
const service = JobBoosterService()

const isSameDateRange = (dateRange1, dateRange2) => DateRange(dateRange1).equal(dateRange2)
const isSameLocale = (locale1, locale2) => locale1(Localization.LOCALE_TAG) === locale2(Localization.LOCALE_TAG)

export const ProAnalyticsFilters = ({ params, setParams, view }) => {
    const { locale, language } = stateOf(Localization)
    const { isAdminUser } = stateOf(Authorization)
    const { user } = stateOf(UserSession)
    const { features } = stateOf(PluginManager)

    const isPMSAgency = user?.njc?.mandate?.agency === 'PMS'
    const pmsFiltersVisible = isPMSAgency || isAdminUser

    const [isOrganisationStructureFilterValid, setIsOrganisationStructureFilterValid] = useState(false)
    const [selectedOrganisationNodes, setSelectedOrganisationNodes] = useState()

    const [filterDictionaries, setFilterDictionaries] = useState()
    const [recruitersDictionary, setRecruitersDictionary] = useState()
    const [mediaDictionary, setMediaDictionary] = useState()
    const [fieldsOfActivityDictionary, setFieldsOfActivityDictionary] = useState()
    const [industriesDictionary, setIndustriesDictionary] = useState()
    const [atsIdsDictionary, setAtsIdsDictionary] = useState()

    const [dictionariesRequestStatus, setDictionariesRequestStatus] = useState()
    const [recruitersDictionaryStatus, setRecruitersDictionaryStatus] = useState()
    const [mediaDictionaryStatus, setMediaDictionaryStatus] = useState()
    const [fieldsOfActivityDictionaryStatus, setFieldsOfActivityDictionaryStatus] = useState()
    const [industriesDictionaryStatus, setIndustriesDictionaryStatus] = useState()
    const [atsIdsDictionaryStatus, setAtsIdsDictionaryStatus] = useState()

    const [dictionariesRequestAbortController, setDictionariesRequestAbortController] = useState()
    const [recruitersDictionaryAbortController, setRecruitersDictionaryAbortController] = useState()
    const [mediaDictionaryAbortController, setMediaDictionaryAbortController] = useState()
    const [fieldsOfActivityDictionaryAbortController, setFieldsOfActivityDictionaryAbortController] = useState()
    const [industriesDictionaryAbortController, setIndustriesDictionaryAbortController] = useState()
    const [atsIdsDictionaryAbortController, setAtsIdsDictionaryAbortController] = useState()

    const SEARCH_FIELDS = getSearchFields(locale)
    const DICTIONARY_FIELDS = getDictionaryFields(locale)

    const organizationStructureFilter = ProAnalyticsOrganizationFilter({
        params,
        setParams,
        view,
    })

    const getFilterDictionaries = useCallback(
        async (
            dictionaryParams = { from: undefined, to: undefined, hierarchyNodeId: undefined },
            locale,
            abortController
        ) => {
            const [error, dictionaries] = await tryCatch(service.getProAnalyticsDictionaries)(
                dictionaryParams,
                abortController.signal
            )

            if (error) {
                if (error?.type === JobBoosterServiceError.ABORT_ERROR) {
                    throw error
                } else {
                    logger.error.withError(
                        error,
                        'An error occurred while loading ProAnalytics filterDictionaries with following params',
                        dictionaryParams
                    )
                    throw new Error('An error occurred while loading ProAnalytics filterDictionaries')
                }
            }

            const mapper = medium => ({
                key: medium['media_id.keyword'],
                name: medium['medienname.keyword'],
                value: medium['media_id.keyword'],
                count: medium.count,
                label: medium['medienname.keyword'],
            })

            const identityCheck = (medium1, medium2) => medium1['media_id.keyword'] === medium2['media_id.keyword']
            const online = dictionaries.medien.online || []
            const onlinePpd = dictionaries.medien.online_PPD || []
            const onlinePpp = dictionaries.medien.online_PPP || []
            const privateMedia = dictionaries.medien.privat || []
            const privatePpd = dictionaries.medien.privat_PPD || []
            const privatePpp = dictionaries.medien.privat_PPP || []
            const print = dictionaries.medien.print || []
            const printPpd = dictionaries.medien.print_PPD || []
            const crawlerPpd = dictionaries.medien.crawler_PPD || []
            const crawlerPpp = dictionaries.medien.crawler_PPP || []
            const ppd = [...online, ...onlinePpd, ...privateMedia, ...privatePpd, ...crawlerPpd]
            const ppp = [...onlinePpp, ...privatePpp, ...crawlerPpp]

            const ppdGroup = distinct(ppd, identityCheck).map(mapper)
            const pppGroup = distinct(ppp, identityCheck).map(mapper)
            const printGroup = distinct([...print, ...printPpd], identityCheck).map(mapper)

            const languageCode = locale(LOCALE_TAG).split('-')[0]

            const recruitersCompareFunction = (recruiter1, recruiter2) =>
                recruiter1.recruiter_id === recruiter2.recruiter_id
            /**
             * TODO -> Change fields of activity label/name code when translations are being updated on the backend
             * ? Do we have translations for all the select options
             * ? What do we do in case we don't have a particular translation
             */
            const filters = {
                recruiters: distinct(dictionaries.recruiters, recruitersCompareFunction).map(recruiter => ({
                    id: recruiter.recruiter_id,
                    name: recruiter.recruiter_name_keyword,
                    value: recruiter.recruiter_id,
                    count: recruiter.count,
                    label: recruiter.recruiter_name_keyword,
                })),
                fieldsOfActivity: dictionaries[DICTIONARY_FIELDS.fieldOfActivity.key].map(entry => ({
                    id: entry[SEARCH_FIELDS.fieldOfActivity.key],
                    name: entry['value.' + languageCode] || entry['value.' + 'de'],
                    value: entry[SEARCH_FIELDS.fieldOfActivity.key],
                    count: entry.count,
                    label: entry['value.' + languageCode] || entry['value.' + 'de'],
                })),
                industries: dictionaries[DICTIONARY_FIELDS.industry.key]
                    .map(entry => ({
                        id: entry[SEARCH_FIELDS.industry.key],
                        name: entry['value.' + languageCode],
                        value: entry[SEARCH_FIELDS.industry.key],
                        count: entry.count,
                        label: entry['value.' + languageCode],
                    }))
                    .filter(entry => entry.label || entry.name)
                    .sort((a, b) => a.name.localeCompare(b.name)),
                atsIds: dictionaries[DICTIONARY_FIELDS.atsId.key]?.map(entry => ({
                    id: entry[SEARCH_FIELDS.atsId.key],
                    name: entry[SEARCH_FIELDS.atsId.key],
                    value: entry[SEARCH_FIELDS.atsId.key],
                    count: entry.count,
                    label: entry[SEARCH_FIELDS.atsId.key],
                })),
                media: [],
            }

            if (ppdGroup.length)
                filters.media.push({
                    label: locale('media.ppd'),
                    key: 'ppd',
                    value: 'ppd',
                    count: ppdGroup.reduce((sum, entry) => sum + entry.count, 0),
                    children: ppdGroup,
                })
            if (pppGroup.length)
                filters.media.push({
                    label: locale('media.ppp'),
                    key: 'ppp',
                    value: 'ppp',
                    count: pppGroup.reduce((sum, entry) => sum + entry.count, 0),
                    children: pppGroup,
                })
            if (printGroup.length)
                filters.media.push({
                    label: locale('media.print'),
                    key: 'print',
                    value: 'print',
                    count: printGroup.reduce((sum, entry) => sum + entry.count, 0),
                    children: printGroup,
                })
            return filters
        }
    )

    const updateFilterDictionaries = useMemoLast(
        async (dateRange = {}, organizationNodes = [], locale) => {
            const abortController = abortAndCreateNewController(dictionariesRequestAbortController)
            setDictionariesRequestAbortController(abortController)
            setDictionariesRequestStatus(createPendingStatus())
            const dictionaryParams = {
                from: dateRange?.from,
                to: dateRange?.to,
                filters: { [SEARCH_FIELDS.hierarchyNodeIds.key]: organizationNodes },
            }
            const [error, filterDictionaries] = await tryCatch(getFilterDictionaries)(
                dictionaryParams,
                locale,
                abortController
            )
            if (error && error.type !== JobBoosterServiceError.ABORT_ERROR) {
                const logEntry = logger.error.withError(error, 'Could not update filter dictionaries')
                setDictionariesRequestStatus(
                    createErrorStatus(
                        locale('proAnalytics.dictionaries.error', {
                            logNumber: logEntry.logNumber,
                        })
                    )
                )
                return undefined
            }
            setDictionariesRequestStatus(createSuccessStatus())
            return filterDictionaries
        },
        [isSameDateRange, haveSameElements, isSameLocale]
    )

    const updateRecruitersDictionary = useCallback(async () => {
        const abortController = abortAndCreateNewController(recruitersDictionaryAbortController)
        setRecruitersDictionaryAbortController(abortController)
        setRecruitersDictionaryStatus(createPendingStatus())
        const requestParams = {
            from: params.dateRangeFilter?.from,
            to: params.dateRangeFilter?.to,
            filters: {
                [SEARCH_FIELDS.hierarchyNodeIds.key]: selectedOrganisationNodes || [],
                [SEARCH_FIELDS.mediumId.key]: params.mediaFilter || [],
                [SEARCH_FIELDS.fieldOfActivity.key]: params.fieldOfActivityFilter || [],
                [SEARCH_FIELDS.industry.key]: params.industryFilter || [],
                [SEARCH_FIELDS.atsId.key]: params.atsFilter || [],
                [SEARCH_FIELDS.postingId.key]: params.postingId ? [params.postingId] : [],
                [SEARCH_FIELDS.publicationId.key]: params.publicationId ? [params.publicationId] : [],
                [SEARCH_FIELDS.orderId.key]: params.orderId ? [params.orderId] : [],
            },
        }
        const [error, dictionary] = await tryCatch(getFilterDictionaries)(requestParams, locale, abortController)
        // Merge the result with all recruiters relevant for chosen date range and organization nodes:
        const nextRecruitersDictionary = (filterDictionaries?.recruiters || []).map(recruiter => {
            const availableEntry = dictionary?.recruiters?.find(entry => entry.id === recruiter.id)
            const count = availableEntry ? availableEntry.count : 0
            return { ...recruiter, count }
        })
        // .sort((item1, item2) => Math.sign(item2.count - item1.count))
        if (error && error.type !== JobBoosterServiceError.ABORT_ERROR) {
            setRecruitersDictionaryStatus(createErrorStatus(error))
            setRecruitersDictionary(undefined)
        } else {
            setRecruitersDictionaryStatus(createSuccessStatus())
            setRecruitersDictionary(formatDictionary(nextRecruitersDictionary))
        }
    })

    const updateMediaDictionary = useCallback(async () => {
        const abortController = abortAndCreateNewController(mediaDictionaryAbortController)
        setMediaDictionaryAbortController(abortController)
        setMediaDictionaryStatus(createPendingStatus())
        const requestParams = {
            from: params.dateRangeFilter?.from,
            to: params.dateRangeFilter?.to,
            filters: {
                [SEARCH_FIELDS.recruiterId.key]: params.recruiterFilter || [],
                [SEARCH_FIELDS.recruiterKeyword.key]: params.recruiterKeyword || [],
                [SEARCH_FIELDS.hierarchyNodeIds.key]: selectedOrganisationNodes || [],
                [SEARCH_FIELDS.fieldOfActivity.key]: params.fieldOfActivityFilter || [],
                [SEARCH_FIELDS.industry.key]: params.industryFilter || [],
                [SEARCH_FIELDS.atsId.key]: params.atsFilter || [],
                [SEARCH_FIELDS.postingId.key]: params.postingId ? [params.postingId] : [],
                [SEARCH_FIELDS.publicationId.key]: params.publicationId ? [params.publicationId] : [],
                [SEARCH_FIELDS.orderId.key]: params.orderId ? [params.orderId] : [],
            },
        }
        const [error, dictionary] = await tryCatch(getFilterDictionaries)(requestParams, locale, abortController)
        // Merge the result with all media relevant for chosen date range and organization nodes:
        const mediaDictionary = filterDictionaries.media.map(group => {
            const children = group.children.map(medium => {
                const availableEntry = dictionary?.media
                    .find(g => g.key === group.key)
                    ?.children.find(entry => entry.key === medium.key)
                const count = availableEntry ? availableEntry.count : 0
                return { ...medium, count }
            })
            // .sort((item1, item2) => Math.sign(item2.count - item1.count))
            const totalCount = children.reduce((sum, medium) => sum + medium.count, 0)
            return {
                ...group,
                count: totalCount,
                children,
            }
        })
        if (error && error.type !== JobBoosterServiceError.ABORT_ERROR) {
            setMediaDictionaryStatus(createErrorStatus(error))
            setMediaDictionary(undefined)
        } else {
            setMediaDictionaryStatus(createSuccessStatus())
            setMediaDictionary(formatMediaDictionary(mediaDictionary))
        }
    })

    const updateFieldsOfActivityDictionary = useCallback(async () => {
        const abortController = abortAndCreateNewController(fieldsOfActivityDictionaryAbortController)
        setFieldsOfActivityDictionaryAbortController(abortController)
        setFieldsOfActivityDictionaryStatus(createPendingStatus())
        const requestParams = {
            from: params.dateRangeFilter?.from,
            to: params.dateRangeFilter?.to,
            filters: {
                [SEARCH_FIELDS.hierarchyNodeIds.key]: selectedOrganisationNodes || [],
                [SEARCH_FIELDS.mediumId.key]: params.mediaFilter || [],
                [SEARCH_FIELDS.recruiterId.key]: params.recruiterFilter || [],
                [SEARCH_FIELDS.industry.key]: params.industryFilter || [],
                [SEARCH_FIELDS.atsId.key]: params.atsFilter || [],
                [SEARCH_FIELDS.postingId.key]: params.postingId ? [params.postingId] : [],
                [SEARCH_FIELDS.publicationId.key]: params.publicationId ? [params.publicationId] : [],
                [SEARCH_FIELDS.orderId.key]: params.orderId ? [params.orderId] : [],
            },
        }
        const [error, dictionary] = await tryCatch(getFilterDictionaries)(requestParams, locale, abortController)
        // Merge the result with all fields of activity relevant for chosen date range and organization nodes:
        const nextFieldsOfActivityDictionary = (filterDictionaries?.fieldsOfActivity || []).map(fieldOfActivity => {
            const value = fieldOfActivity.name
            const count = value
                ? dictionary?.fieldsOfActivity
                      .filter(entry => entry.name === fieldOfActivity.name)
                      .reduce((sum, entry) => sum + entry.count, 0)
                : 0
            return { ...fieldOfActivity, count }
        })
        // .sort((item1, item2) => Math.sign(item2.count - item1.count))
        if (error && error.type !== JobBoosterServiceError.ABORT_ERROR) {
            setFieldsOfActivityDictionaryStatus(createErrorStatus(error))
            setFieldsOfActivityDictionary(undefined)
        } else {
            setFieldsOfActivityDictionaryStatus(createSuccessStatus())
            setFieldsOfActivityDictionary(formatDictionary(nextFieldsOfActivityDictionary))
        }
    })

    const updateIndustriesDictionary = useCallback(async () => {
        const abortController = abortAndCreateNewController(industriesDictionaryAbortController)
        setIndustriesDictionaryAbortController(abortController)
        setIndustriesDictionaryStatus(createPendingStatus())
        const requestParams = {
            from: params.dateRangeFilter?.from,
            to: params.dateRangeFilter?.to,
            filters: {
                [SEARCH_FIELDS.hierarchyNodeIds.key]: selectedOrganisationNodes || [],
                [SEARCH_FIELDS.mediumId.key]: params.mediaFilter || [],
                [SEARCH_FIELDS.recruiterId.key]: params.recruiterFilter || [],
                [SEARCH_FIELDS.fieldOfActivity.key]: params.fieldOfActivityFilter || [],
                [SEARCH_FIELDS.atsId.key]: params.atsFilter || [],
                [SEARCH_FIELDS.postingId.key]: params.postingId ? [params.postingId] : [],
                [SEARCH_FIELDS.publicationId.key]: params.publicationId ? [params.publicationId] : [],
                [SEARCH_FIELDS.orderId.key]: params.orderId ? [params.orderId] : [],
            },
        }
        const [error, dictionary] = await tryCatch(getFilterDictionaries)(requestParams, locale, abortController)
        // Merge the result with all fields of activity relevant for chosen date range and organization nodes:
        const nextIndustriesDictionary = (filterDictionaries?.industries || []).map(industry => {
            const availableEntry = dictionary?.industries.find(entry => entry.id === industry.id)
            const count = availableEntry ? availableEntry.count : 0
            return { ...industry, count }
        })
        // .sort((item1, item2) => Math.sign(item2.count - item1.count))
        if (error && error.type !== JobBoosterServiceError.ABORT_ERROR) {
            setIndustriesDictionaryStatus(createErrorStatus(error))
            setIndustriesDictionary(undefined)
        } else {
            setIndustriesDictionaryStatus(createSuccessStatus())
            setIndustriesDictionary(formatDictionary(nextIndustriesDictionary))
        }
    })

    const updateAtsIdsDictionary = useCallback(async () => {
        const abortController = abortAndCreateNewController(atsIdsDictionaryAbortController)
        setAtsIdsDictionaryAbortController(abortController)
        setAtsIdsDictionaryStatus(createPendingStatus())
        const requestParams = {
            from: params.dateRangeFilter?.from,
            to: params.dateRangeFilter?.to,
            filters: {
                [SEARCH_FIELDS.hierarchyNodeIds.key]: selectedOrganisationNodes || [],
                [SEARCH_FIELDS.mediumId.key]: params.mediaFilter || [],
                [SEARCH_FIELDS.recruiterId.key]: params.recruiterFilter || [],
                [SEARCH_FIELDS.fieldOfActivity.key]: params.fieldOfActivityFilter || [],
                [SEARCH_FIELDS.industry.key]: params.industryFilter || [],
                [SEARCH_FIELDS.postingId.key]: params.postingId ? [params.postingId] : [],
                [SEARCH_FIELDS.publicationId.key]: params.publicationId ? [params.publicationId] : [],
                [SEARCH_FIELDS.orderId.key]: params.orderId ? [params.orderId] : [],
            },
        }
        const [error, nextAtsDictionary] = await tryCatch(getFilterDictionaries)(requestParams, locale, abortController)
        if (error && error.type !== JobBoosterServiceError.ABORT_ERROR) {
            setAtsIdsDictionaryStatus(createErrorStatus(error))
            setAtsIdsDictionary(undefined)
        } else {
            setAtsIdsDictionaryStatus(createSuccessStatus())
            setAtsIdsDictionary(formatDictionary(nextAtsDictionary.atsIds))
        }
    })

    /**
     * Formats all entries' labels to "Label (count)"
     */
    const formatDictionary = useMemo(dictionary =>
        dictionary
            ? dictionary
                  .map(entry => ({
                      ...entry,
                      label: `${entry.label} (${locale('numberFormatter', entry.count)})`,
                  }))
                  .sort((a, b) => a.label?.localeCompare(b.label))
            : undefined
    )

    /**
     * Formats all entries' labels to "Label (count)" including group labels
     */
    const formatMediaDictionary = useMemo(dictionary =>
        dictionary
            ? dictionary.map(group => {
                  const children = group.children.map(medium => {
                      const count = medium.count || 0
                      const label = `${medium.label} (${locale('numberFormatter', count)})`
                      return { ...medium, count, label }
                  })
                  const totalCount = group.children.reduce((sum, medium) => sum + medium.count, 0)
                  return {
                      ...group,
                      count: totalCount,
                      label: `${group.label} (${locale('numberFormatter', totalCount)})`,
                      children,
                  }
              })
            : undefined
    )

    const onDateRangeChange = useCallback(value => setParams({ dateRangeFilter: value }))

    const onRecruitersChange = useCallback(value => {
        const nextParams = { recruiterFilter: value }
        setParams(nextParams)
    })

    const resetFilters = useCallback(() => {
        setParams({
            textFilter: undefined,
            internalTitle: undefined,
            externalTitle: undefined,
            postingId: undefined,
            publicationId: undefined,
            orderId: undefined,
            recruiterKeyword: undefined,
            recruiterFilter: [],
            mediaFilter: undefined,
            organizationStructureFilter: undefined,
            fieldOfActivityFilter: [],
            industryFilter: [],
            atsFilter: [],
            dateRangeFilter: getDefaultDateRange(),
        })
    })

    const updateFilters = useCallback((dictionaries, params, locale) => {
        const selectedRecruiters = params.recruiterFilter?.filter(id =>
            dictionaries.recruiters.some(entry => entry.id === id)
        )

        const allMedia = dictionaries.media.flatMap(group => group.children)
        const selectedMedia = params.mediaFilter?.filter(key => allMedia.some(medium => medium.key === key))

        const selectedFieldsOfActivity = params.fieldOfActivityFilter
            // Filter out entries which are no longer in the dictionary:
            ?.filter(id => dictionaries.fieldsOfActivity.some(entry => entry.id.toString() === id))
            // Map the rest to the name field, filter out undefined and deduplicate:
            .map(id => dictionaries.fieldsOfActivity.find(entry => entry.id.toString() === id)?.name)
            .filter(name => name !== undefined)
            .reduce(deduplicate, [])
            // Find all entries in the dictionary with the same name and return their distinct IDs:
            .reduce(
                (entries, name) => [...entries, ...dictionaries.fieldsOfActivity.filter(entry => entry.name === name)],
                []
            )
            .map(entry => entry.id)
            .reduce(deduplicate, [])
        if (!haveSameElements(params.fieldOfActivityFilter, selectedFieldsOfActivity))
            infoNotification(
                locale('proAnalytics.fieldOfActivityFilterUpdate', { language: locale('language.' + language) })
            )

        const selectedIndustries = params.industryFilter?.filter(id =>
            dictionaries.industries.some(entry => entry.id.toString() === id)
        )

        const selectedAtsIds = params.atsFilter?.filter(id =>
            dictionaries.atsIds.some(entry => entry.id.toString() === id)
        )

        const filters = {
            recruiterFilter: selectedRecruiters,
            mediaFilter: selectedMedia,
            fieldOfActivityFilter: selectedFieldsOfActivity,
            industryFilter: selectedIndustries,
            atsFilter: selectedAtsIds,
        }

        setParams(filters)

        return filters
    })

    const viewUpdate = fields => {
        fields.pmsFilters.visible = pmsFiltersVisible
        fields.dateRangeFilter.value = params.dateRangeFilter
        fields.dateRangeFilter.onChange = onDateRangeChange

        organizationStructureFilter.viewUpdate(fields)

        fields.dictionariesStatus.value = dictionariesRequestStatus

        const recruitersDisabled =
            dictionariesRequestStatus?.status === STATUS_PENDING ||
            !isOrganisationStructureFilterValid ||
            !recruitersDictionary?.length
        fields.recruiterFilter.dictionary = recruitersDictionary
        fields.recruiterFilter.getDictionary = updateRecruitersDictionary
        fields.recruiterFilter.isLoading = recruitersDictionaryStatus?.status === STATUS_PENDING
        fields.recruiterFilter.value = !recruitersDisabled ? params.recruiterFilter : undefined
        fields.recruiterFilter.onChange = onRecruitersChange
        fields.recruiterFilter.disabled = recruitersDisabled

        const mediaDisabled =
            dictionariesRequestStatus?.status === STATUS_PENDING ||
            !isOrganisationStructureFilterValid ||
            !mediaDictionary?.length
        fields.mediaFilter.dictionary = mediaDictionary
        fields.mediaFilter.getDictionary = updateMediaDictionary
        fields.mediaFilter.isLoading = mediaDictionaryStatus?.status === STATUS_PENDING
        fields.mediaFilter.value = !mediaDisabled ? params.mediaFilter : undefined
        fields.mediaFilter.onChange = value => setParams({ mediaFilter: value })
        fields.mediaFilter.disabled = mediaDisabled

        const fieldsOfActivityDisabled =
            dictionariesRequestStatus?.status === STATUS_PENDING ||
            !isOrganisationStructureFilterValid ||
            !fieldsOfActivityDictionary?.length
        fields.fieldOfActivityFilter.dictionary = FieldsOfActivity(fieldsOfActivityDictionary).fieldsOfActivityByLabel
        fields.fieldOfActivityFilter.visible = features?.proAnalytics?.filters?.fieldOfActivity?.visibility?.inject()
        fields.fieldOfActivityFilter.getDictionary = updateFieldsOfActivityDictionary
        fields.fieldOfActivityFilter.isLoading = fieldsOfActivityDictionaryStatus?.status === STATUS_PENDING
        fields.fieldOfActivityFilter.value = !fieldsOfActivityDisabled
            ? FieldsOfActivity(fieldsOfActivityDictionary).getValuesByIds(params.fieldOfActivityFilter)
            : undefined
        fields.fieldOfActivityFilter.onChange = values =>
            setParams({
                fieldOfActivityFilter: FieldsOfActivity(fieldsOfActivityDictionary).getIdsByValues(values),
            })
        fields.fieldOfActivityFilter.disabled = fieldsOfActivityDisabled

        const industriesDisabled =
            dictionariesRequestStatus?.status === STATUS_PENDING ||
            !isOrganisationStructureFilterValid ||
            !industriesDictionary?.length
        fields.industryFilter.dictionary = industriesDictionary
        fields.industryFilter.visible = features?.proAnalytics?.filters?.industryFilter?.visibility?.inject()
        fields.industryFilter.getDictionary = updateIndustriesDictionary
        fields.industryFilter.isLoading = industriesDictionaryStatus?.status === STATUS_PENDING
        fields.industryFilter.value = !industriesDisabled ? params.industryFilter : undefined
        fields.industryFilter.onChange = value => setParams({ industryFilter: value })
        fields.industryFilter.disabled = industriesDisabled

        const atsIdsDisabled =
            dictionariesRequestStatus?.status === STATUS_PENDING ||
            !isOrganisationStructureFilterValid ||
            !atsIdsDictionary?.length
        fields.atsFilter.dictionary = atsIdsDictionary
        fields.atsFilter.visible = features?.proAnalytics?.filters?.atsFilter?.visibility?.inject()
        fields.atsFilter.getDictionary = updateAtsIdsDictionary
        fields.atsFilter.isLoading = atsIdsDictionaryStatus?.status === STATUS_PENDING
        fields.atsFilter.value = !atsIdsDisabled ? params.atsFilter : undefined
        fields.atsFilter.onChange = value => setParams({ atsFilter: value })
        fields.atsFilter.disabled = atsIdsDisabled

        fields.resetFilters.onTrigger = resetFilters
    }

    const update = async () => {
        let filters = { ...params }
        let nextFilterDictionaries = filterDictionaries
        const organizationFilterState = await organizationStructureFilter.update()
        filters.organizationStructureFilter = organizationFilterState.selectedOrganizationNodes
        setSelectedOrganisationNodes(organizationFilterState.selectedOrganizationNodes)
        setIsOrganisationStructureFilterValid(organizationFilterState.isOrganisationStructureFilterValid)
        if (organizationFilterState.isOrganisationStructureFilterValid) {
            nextFilterDictionaries =
                (await updateFilterDictionaries(
                    params.dateRangeFilter,
                    organizationFilterState.selectedOrganizationNodes,
                    locale
                )) || filterDictionaries
            if (nextFilterDictionaries !== filterDictionaries) {
                setRecruitersDictionary(formatDictionary(nextFilterDictionaries.recruiters))
                setMediaDictionary(formatMediaDictionary(nextFilterDictionaries.media))
                setFieldsOfActivityDictionary(nextFilterDictionaries.fieldsOfActivity)
                setIndustriesDictionary(nextFilterDictionaries.industries)
                setAtsIdsDictionary(nextFilterDictionaries.atsIds)
                const updatedFilters = updateFilters(nextFilterDictionaries, params, locale)
                filters = {
                    ...params,
                    ...updatedFilters,
                    organizationStructureFilter: organizationFilterState.selectedOrganizationNodes,
                }
            }
            setFilterDictionaries(nextFilterDictionaries)
        }
        return { filters, filterDictionaries: nextFilterDictionaries }
    }

    return { update, viewUpdate, filterDictionaries }
}
