import { useEffect, useState } from 'react'
import { requiredValidator } from '@prospective/pms-view-context'
import { O } from '@prospective/pms-js-utils'

/*export function withViewModel(Component, contextType) {
    return function (props) {
        const viewModel = useViewModel(contextType)
        return <Component viewModel={viewModel} {...props} />
    }
}*/

/**
 * React custom hook returning a viewModel for given context type
 * @param context
 */
export function useViewModel(context) {
    const [validationMessages, setValidationMessages] = useState({})
    const [viewDescriptor, setViewDescriptor] = useState({
        values: context.values,
        metadata: context.metadata,
        errors: context.errors,
        eventTriggers: context.eventTriggers
    })

    const contextUpdateHandler = ({ values, metadata, errors, eventTriggers, validationMessages }) => {
        setViewDescriptor({
            values,
            metadata,
            errors,
            eventTriggers
        })
        setValidationMessages(validationMessages)
    }

    useEffect(() => {
        context.subscribe(contextUpdateHandler)
        contextUpdateHandler({
            values: context.values,
            metadata: context.metadata,
            errors: context.errors,
            eventTriggers: context.eventTriggers,
            validationMessages: context.validationMessages,
        })
        return () => {
            if (context) context.unsubscribe(contextUpdateHandler)
        }
    }, [])

    const setValue = (context, property, value) => {
        context.updateState({ values: { [property]: value } })
    }

    /**
     * @type ViewModelObject
     */
    const viewModel = O(viewDescriptor.metadata)
        .map((descriptor, property) => {
            /**
             * @type {FieldModel}
             */
            const fieldModel = {
                value: viewDescriptor.values[property],
                default: descriptor.default,
                setValue: value => {
                    setValue(context, property, value)
                    context.eventTriggers[property].onChange(value)
                    return fieldModel
                },
                label: descriptor.label,
                disabled: descriptor.disabled ? "disabled" : "",
                visible: !(descriptor.visible === false),
                dictionary: descriptor.dictionary ? descriptor.dictionary : undefined,
                placeholder: descriptor.placeholder,
                validationMessages: validationMessages[property] || [],
                isInvalid: validationMessages[property]?.length > 0,
                isValid: (validationMessages[property] || []).length === 0,
                isRequired: descriptor.validators?.some((validator) => validator.type === requiredValidator),
                validationStatus: (validationMessages[property] || []).length ? "error" : undefined,
                clearValidationState: () => context.clearValidation(property),
                validate: () => {
                    const validationResult = context.validate(property)
                    const nextValidationMessages = O(validationMessages)
                        .excluding(property)
                        .merge(validationResult)
                        .valueOf()
                    setValidationMessages(nextValidationMessages)
                    return O(validationResult).size === 0
                },
                ...O(viewDescriptor.eventTriggers[property])
                    .map((trigger, eventName) => (...args) => {
                        // trigger(...args)
                        context.eventTriggers[property][eventName](...args)
                        return fieldModel
                    })
                    .valueOf()
            }

            const customFields = O(descriptor).excluding(
                "default",
                "label",
                "disabled",
                "visible",
                "dictionary",
                "placeholder",
                "actions"
            )
            customFields.forEach((value, field) => (fieldModel[field] = value))
            return fieldModel
        })
        .valueOf()

    viewModel.validate = (...fields) => {
        const validationResult = context.validate(...fields)
        setValidationMessages(validationResult)
        return validationResult
    }

    viewModel.getValues = () => viewDescriptor.values
    viewModel.errors = O(viewDescriptor.errors).filter(value => value).valueOf()
    viewModel.validationMessages = validationMessages
    return viewModel
}
