import JobBooster from '@services/job_booster_service'
import { distinct, O, tryCatch } from '@prospective/pms-js-utils'
import * as xlsx from 'xlsx'
import { buildSuggestionId, getSearchFields } from '@views/pro_analytics/pro_analytics_utils.js'

let queriedReportsAbortController

const service = JobBooster()

const abortAndCreateNewController = currentController => {
    if (currentController) currentController.abort()
    const controller = new AbortController()
    return controller
}

/**
 * ? If reports data is empty what do we do ? We would display a message or an empty XLS
 */

export const getReports = async (reports, reportId, params) => {
    queriedReportsAbortController = abortAndCreateNewController(queriedReportsAbortController)

    const report = reports.find(report => report.id === +reportId)

    if (!report || !reportId) return

    const [error, data] = await tryCatch(service.generateReport)(
        params,
        queriedReportsAbortController.signal,
        report
    )

    return [error, data]
}

const mergeArrToJSON = (a, b) =>
    a.map((item, i) => ({ [item.toLowerCase()]: b[i] })).reduce((json, val) => Object.assign({}, json, val))

const getAggregation = (reportData = []) => {
    const reportDataHeaders = reportData?.header?.map(header => header.replace(/,/g, ''))

    const columns = reportDataHeaders.map(headItem => ({
        title: headItem.toLowerCase(),
    }))

    const dataSource = reportData?.data?.reduce((obj, report) => {
        obj.push(mergeArrToJSON(reportDataHeaders, report))
        return obj
    }, [])

    return {
        columns,
        dataSource,
    }
}

export const getXlsReport = async (data = [], reportName, dateRange, locale) => {
    const { columns, dataSource } = getAggregation(data)
    const { from, to } = dateRange

    const fileName = `${reportName}_${locale('dateFormatter', from)}-${locale('dateFormatter', to)}`

    const workbook = xlsx.utils.book_new()
    const worksheet = xlsx.utils.json_to_sheet(dataSource, {
        header: columns.map(column => column.title),
    })

    xlsx.utils.book_append_sheet(workbook, worksheet, 'Report details')
    xlsx.writeFile(workbook, fileName + '.xlsx')
}

export const formatSearchSuggestion = (searchResult, searchTerm, locale, searchLimit) => {
    const groupByMatch = (result = { results: [] }, searchTerm = '', locale, searchLimit) => {
        const fields = [...new Set(result.results?.map(item => item.match))]
        const hitCountString = result.hit_count < searchLimit ? result.hit_count : searchLimit + '+'

        /*const all = {
            match: 'all',
            label: locale('proAnalytics.search.termEverywhere', {
                searchTerm,
                hitCount: hitCountString,
            }),
            searchTerm,
            hitCount: result.hit_count,
            value: `all.${searchTerm}`,
        }*/
        const SEARCH_FIELDS = getSearchFields(locale)
        const groupedResult = fields.reduce((grouped, field) => {
            const fieldMatch = result.results.filter(item => item.match === field)
            const fieldName = O(SEARCH_FIELDS)
                .find(descriptor => descriptor.key === field)
                ?.valueOf().label
            const suggestions = distinct(fieldMatch, (item1, item2) => item1[field] === item2[field])
                .slice(0, 5)
                .map(suggestion => ({
                    label: suggestion[suggestion.match],
                    searchTerm,
                    value: buildSuggestionId(suggestion),
                    ...suggestion,
                }))
            const hitCount = suggestions.length < searchLimit ? suggestions.length : searchLimit + '+'
            let label = ''
            if (hitCount !== undefined)
                label = locale('proAnalytics.search.termInColumnWithHitCount', {
                    searchTerm,
                    fieldName,
                    hitCount,
                })
            else
                label = locale('proAnalytics.search.termInColumn', {
                    searchTerm,
                    fieldName,
                })

            const value = `${field}-${label}`
            grouped.push({ group: field, field, value, searchTerm, label, hitCount, suggestions })

            return grouped
        }, [])
        return {
            searchTerm,
            hitCount: result.hit_count,
            suggestions: searchTerm.length ? groupedResult.filter(item =>
                item.group !== SEARCH_FIELDS.atsId.key
                && item.group !== SEARCH_FIELDS.orderId.key
                && item.group !== SEARCH_FIELDS.publicationId.key
                && item.group !== SEARCH_FIELDS.postingId.key)
             : [],
        }
    }
    return groupByMatch(searchResult, searchTerm, locale, searchLimit)
}