import { Button, Col, Row, Space, theme, Tooltip, Typography } from 'antd'
import { Alert, Check, ClockCircle } from '@assets/icons/icons'
import { jbStatusErrorFill, jbStatusPendingFill, jbStatusSuccessFill } from '@styles/default_theme.js'
import { CountUP } from '@lib/count_up/count_up.jsx'
import Icon, { LoadingOutlined } from '@ant-design/icons'
import { RemoteData } from '@lib/remote_data/remote-data.js'
import { useContext } from 'react'
import { LocaleContext } from '@lib/i18n/localization_provider.jsx'
import { useDebug } from '@utils/debug.js'

export default function TasksStatus({ className = '', tasks = [], style, ...props }) {
    const { token } = theme.useToken()
    const { locale } = useContext(LocaleContext)
    const { openLogViewer, debugMode } = useDebug()

    const allSuccessful = tasks.every(task =>
        task.status?.status === RemoteData.SUCCESS
        // && task.status?.status !== RemoteData.QUEUED
    )
    if (allSuccessful) return null

    const containerStyle = {
        backgroundColor: token.colorBgLayout,
        ...style
    }
    return (
        <div className={`full-space centered-content loading-indicator tasks-status`} style={containerStyle}>
            <div className={`ApplicationStatus ${className}`} {...props}>
                <Typography>
                <Row gutter={[16, 8]} style={{ maxWidth: '450px' }}>
                    {tasks.map((task, index) => {
                        if (!task.status) return null
                        const state = task.status
                        const retry = task.retry
                        const getErrorDetails = task.getErrorDetails
                        const errorDetails = getErrorDetails ?
                            getErrorDetails(locale) :
                            {
                                title: typeof state.error === 'string' ? state.error : state.error?.(locale),
                                logNumber: state.logNumber,
                            }
                        return (
                            <Col span={24} key={index}>
                                <Row gutter={16} wrap={false}>
                                    <Col flex="36px">
                                        {(state.status === RemoteData.QUEUED || !state.status) && (
                                            <Icon component={() => <ClockCircle />} />
                                        )}
                                        {state.status === RemoteData.PENDING && (
                                            <LoadingOutlined style={{ color: jbStatusPendingFill }} spin />
                                        )}
                                        {state.status === RemoteData.SUCCESS && (
                                            <Icon component={() => <Check />} style={{ color: jbStatusSuccessFill }} />
                                        )}
                                        {state.status === RemoteData.ERROR && (
                                            <Icon component={() => <Alert />} style={{ color: jbStatusErrorFill }} />
                                        )}
                                    </Col>
                                    <Col flex="auto">
                                        <Row gutter={16} wrap={false}>
                                            <Col flex="auto">
                                                <div>
                                                <Tooltip title={errorDetails?.description}>
                                                    <div style={{ maxWidth: '300px' }}>{task.label}</div>
                                                </Tooltip>
                                                {state.status === RemoteData.ERROR && (
                                                    <Space direction={'vertical'}>
                                                        <Tooltip title={errorDetails.description}>
                                                            <Typography.Text type={'danger'}>{
                                                                errorDetails.description ? errorDetails.description : errorDetails.title
                                                            }</Typography.Text>
                                                        </Tooltip>
                                                        {debugMode && errorDetails.logNumber !== undefined &&
                                                            <Button
                                                                shape="round"
                                                                danger
                                                                className="alert__errors-log"
                                                                size="small"
                                                                onClick={() => openLogViewer(errorDetails.logNumber)}
                                                            >
                                                                Log
                                                            </Button>
                                                        }
                                                        {errorDetails.retry && (
                                                            <Button
                                                                shape={'round'}
                                                                size={'small'}
                                                                onClick={() => errorDetails.retry()}
                                                            >
                                                                Retry
                                                            </Button>
                                                        )}
                                                    </Space>
                                                )}
                                                </div>
                                            </Col>
                                            <Col flex="64px" style={{textAlign: 'right'}}>
                                                <CountUP value={(state.progress || 0) * 100} />%
                                            </Col>
                                            {state.actions &&
                                                <Col span={24}>{
                                                    state.actions.map(action => (
                                                        <Button key={action.label} shape={'round'} onClick={action.callback}
                                                                size={'small'}
                                                        >
                                                            {action.label}
                                                        </Button>
                                                    ))}
                                                </Col>
                                            }
                                        </Row>

                                    </Col>
                                </Row>
                            </Col>
                        )
                    })}
                </Row>
                </Typography>
            </div>
        </div>
    )
}
