import { useEffect, useState } from 'react'
import { O, Publisher } from '@prospective/pms-js-utils'
import { CONFIG } from '@configuration/config_variables.js'

const environment =
    CONFIG.environment === 'stage' ? 'stage' : CONFIG.environment === 'development' ? 'development' : CONFIG.environment

/**
 * @typedef {Object} FeatureToggleInterface
 * @property {function} on - turns the feature on
 * @property {function} off - turns the feature off
 * @property {function} toggle - toggles the feature
 * @property {boolean} value - tells if the feature in on or off
 */

/**
 * @typedef {Object} FeatureTogglesInterface
 * @property {FeatureToggleInterface} cockpit
 * @property {FeatureToggleInterface} settings
 */

const [onChange, publishChange] = Publisher()

const FeatureToggleInterface = featureKey => ({
    on: () => {
        features[featureKey] = true
        state.features = O(features)
            .map((value, key) => FeatureToggleInterface(key))
            .valueOf()
        publishChange(state)
    },
    off: () => {
        features[featureKey] = false
        state.features = O(features)
            .map((value, key) => FeatureToggleInterface(key))
            .valueOf()
        publishChange(state)
    },
    toggle: () => {
        features[featureKey] = !features[featureKey]
        state.features = O(features)
            .map((value, key) => FeatureToggleInterface(key))
            .valueOf()
        publishChange(state)
    },
    get value() {
        return features[featureKey]
    },
})

const features = {
    cockpit: true,
    mediaList: true,
    order: true,
    invoices: true,
    profiles: true,
    applicationChannel: true,
    companyInfo: true,
    signUp: true,
    inviteUser: true,
    jobBoard: true,
    passwordUpdate: false,
    reports: true,
    notifications: false,
    gtm: true
}

const defaultFeatureToggles = O(features)
    .map((value, key) => FeatureToggleInterface(key))
    .valueOf()

let state = {
    features: O(features)
        .map((value, key) => FeatureToggleInterface(key))
        .valueOf(),
}

export const FeatureToggles = {
    get features() {
        return state.features
    },
    get state() {
        return state
    },
    ...onChange,
}

export const FeatureToggleComponent = ({ children, featureName, component = true }) => {
    if (!featureName) console.error('Please provide featureName')
    if (!features[featureName]) return null

    if (!features[featureName] && !component) return <p>The page you are trying to access is restricted.</p>

    return <>{children}</>
}

/**
 * A React hook for feature toggles
 * @return {FeatureTogglesInterface}
 */
export const useFeatureToggles = () => {
    const [featureToggles, setFeatureToggles] = useState(defaultFeatureToggles)

    const updateFeatureToggles = state => {
        setFeatureToggles(state.features)
    }

    useEffect(() => {
        FeatureToggles.subscribe(updateFeatureToggles)
        updateFeatureToggles(FeatureToggles)
        return () => FeatureToggles.unsubscribe(updateFeatureToggles)
    }, [])

    return featureToggles
}

export const FeatureToggleFunc = featureName => features[featureName]
