/**
 * Calculates a linear value between 0 and 1 for given number of frames and frame index
 * @param numberOfFrames
 * @param index
 * ! UNUSED
 */
import { Publisher } from '@prospective/pms-js-utils'
import { Fragment, useState, useEffect } from 'react'

export const linear = (numberOfFrames, index) => {
    return (1 / numberOfFrames) * (index + 1)
}

/**
 * Calculates a cosine value between 0 and 1 for given number of frames and frame index
 * @param numberOfFrames
 * @param index
 */
export const cosine = (numberOfFrames, index) => {
    const argument = (Math.PI / numberOfFrames) * (index + 1)
    const value = (Math.cos(argument + Math.PI) + 1) / 2
    return value
}

/* Plot
     |                 ______|____
     |           __""""      |
     |          /            |
     |       __/             |
 ----|---""""                |
   from         0            to
 */

/**
 * Calculates an arcTangent value between 0 and 1 for given number of frames and frame index
 * @param numberOfFrames
 * @param index
 */
export const arcTangent = (numberOfFrames, index) => {
    const [from, to] = [-5, 5] // change to shift the main animation phase in time
    const multiplier = 4 // change this to get stronger ease-in-out effect
    const argument = ((to - from) / numberOfFrames) * (index + 1) + from
    const range = Math.atan(to * multiplier) - Math.atan(from * multiplier)
    const value = (Math.atan(argument * multiplier) - Math.atan(from * multiplier)) / range
    return value
}

/**
 * Reduces the number of animation frames by finding all subsequent frames that have the same value
 * keeping only one with combined timeout.
 * @param result
 * @param frame
 */
const frameNumberReducer = (result, frame) => {
    const previousFrame = result.length ? result[result.length - 1] : undefined
    const previousValue = previousFrame ? previousFrame.value : undefined
    if (frame.value === previousValue) {
        previousFrame.timeout += frame.timeout
    } else {
        result.push(frame)
    }
    return result
}

/**
 *
 * @param timingFunction
 * @return {Function} Timing function
 */
const getFramesFunction = timingFunction => (initialValue, targetValue, time) => {
    const sampling = 15 // Determines animations frame rate, specified in ms
    const numberOfFrames = Math.ceil(time / sampling) || 0
    const range = targetValue - initialValue
    return Array.from(Array(numberOfFrames))
        .map((item, index) => ({
            value: initialValue + Math.round(timingFunction(numberOfFrames, index) * range),
            timeout: sampling,
        }))
        .reduce(frameNumberReducer, [])
}

export function CountUp(time = 1000, timingFunction = arcTangent) {
    const [onUpdate, publishUpdate] = Publisher()

    let timer
    let displayValue = 0

    const count = (frames, frameIndex) => {
        const frame = frames[frameIndex]
        if (frame) {
            timer = window.setTimeout(() => {
                displayValue = frame.value
                publishUpdate(displayValue)
                count(frames, frameIndex + 1)
            }, frame.timeout)
        }
    }

    const startCounter = value => {
        window.clearTimeout(timer)
        const getFrames = getFramesFunction(timingFunction)
        const frames = getFrames(displayValue, value, time)
        count(frames, 0)
    }

    return { ...onUpdate, startCounter }
}

export function CountUP({ value }) {
    const [countUpValue, setValue] = useState(0)
    const [countUp, setCountUp] = useState(null)

    const valueChangeHandler = value => {
        setValue(value)
    }

    useEffect(() => {
        const countUp = CountUp()
        countUp.subscribe(valueChangeHandler)
        countUp.startCounter(value)
        setCountUp(countUp)
        return () => {
            countUp.unsubscribe(valueChangeHandler)
        }
    }, [])

    useEffect(() => {
        countUp?.startCounter(value)
    }, [value])

    return <Fragment>{countUpValue}</Fragment>
}
